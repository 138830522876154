import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { PriceSelectorItems } from '../PriceSelectorItems';
import { PriceSlider } from '../PriceSlider';
import { LoadingStatuses, OrderItem } from '../../../../../../dataTypes';
import {
  showPurchaseErrorMessage,
  showPurchaseSuccessMessage,
  showReturnErrorMessage,
} from '../../../../../../components/Messages';
import { PURCHASE_ERROR_DESCRIPTION } from '../../../../../../consts';
import styles from './PriceSelector.module.scss';
import { Button, Space } from 'antd';
import { ConfirmableButton } from '../../../../../../components/ConfirmableButton';
import LogoIcon from '../../../../../../images/Logo.svg';
import { useOrderStore } from 'pages/OrderDetails/hooks/useOrderStore';
import { usePriceCalculationStore } from 'pages/OrderDetails/hooks/usePriceCalculationStore';
import useConfig from 'hooks/useConfig';
import { CaptureDepositButton } from 'pages/OrderDetails/components/CaptureDepositButton';
import { CompleteOrderButton } from 'pages/OrderDetails/components/CompleteOrderButton';

interface IPriceSelector {
  onRequestCharge?(): void;
}

export const PriceSelector: FC<IPriceSelector> = observer(({ onRequestCharge }) => {
  const orderStore = useOrderStore();
  const priceCalculationStore = usePriceCalculationStore();
  const { config } = useConfig();

  const order = orderStore.currentOrder;
  const depositAvailable =
    order.deposits && order.deposits.filter((el) => el.status === 'Active').length > 0;

  const orderItemsMap = useMemo(() => {
    const result: Record<OrderItem['id'], OrderItem> = {};

    for (const item of order.items as OrderItem[]) {
      result[item.id] = item;
    }

    return result;
  }, [order]);

  useEffect(() => {
    priceCalculationStore.loadSettingsIfNotLoaded().then(() => {
      priceCalculationStore.setPurchasedItems(order.items as OrderItem[]);
    });
  }, [priceCalculationStore, order]);

  const onBlurSellPriceInput = (): void => {
    if (!config?.allowPurchaseBelowMinimalPrice) {
      const errorMessage = priceCalculationStore.validateStoreState();
      if (errorMessage) {
        showPurchaseErrorMessage(errorMessage);
      }
    }
  };

  const onSubmit = useCallback((): void => {
    if (!config?.allowPurchaseBelowMinimalPrice) {
      const errorMessage = priceCalculationStore.validateStoreState();
      if (errorMessage) {
        showPurchaseErrorMessage(errorMessage);
        return;
      }
    }

    orderStore
      .submitPurchase({
        purchasedItemIds: priceCalculationStore.purchasedItems.map((item) => item.id),
        sellPrice: priceCalculationStore.sellPrice,
      })
      .then(
        () => showPurchaseSuccessMessage(),
        () => showPurchaseErrorMessage(PURCHASE_ERROR_DESCRIPTION),
      );
  }, [config?.allowPurchaseBelowMinimalPrice, orderStore, priceCalculationStore]);

  const onReturn = useCallback((): void => {
    orderStore.setOrderReturned().catch(() => showReturnErrorMessage());
  }, [orderStore]);

  const isLoaded =
    orderStore.loadingStatus === LoadingStatuses.Finished &&
    priceCalculationStore.loadingSettingsStatus === LoadingStatuses.Finished;

  const captureDepositButton = config?.allowCaptureDeposit ? <CaptureDepositButton /> : null;

  return (
    <>
      {isLoaded && (
        <>
          <PriceSelectorItems
            items={order.items as OrderItem[]}
            itemsPurchasedIds={priceCalculationStore.purchasedItems.map((item) => item.id)}
            onChangeItemsPurchasedIds={(ids) => {
              /* istanbul ignore next */
              priceCalculationStore.setPurchasedItems(ids.map((id) => orderItemsMap[id]));
            }}
          />
          <PriceSlider
            sellPrice={priceCalculationStore.sellPrice}
            calculatedPrices={priceCalculationStore.calculatedPrices}
            itemsCount={priceCalculationStore.purchasedItems.length}
            onChangeSellPrice={
              /* istanbul ignore next */
              (newPrice) => priceCalculationStore.setSellPrice(newPrice)
            }
            onBlurSellPriceInput={onBlurSellPriceInput}
          />
          <div className={styles.buttonWrapper}>
            {depositAvailable ? (
              captureDepositButton
            ) : (
              <Space>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={onRequestCharge}
                  className={styles.buttonRequestCharge}
                >
                  <img src={LogoIcon} alt="" />
                  <span>Request Charge</span>
                </Button>
                <CompleteOrderButton />
              </Space>
            )}
            <Space>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={onSubmit}
                className={styles.button}
              >
                <img src={LogoIcon} alt="" />
                <span>Submit</span>
              </Button>
              <ConfirmableButton
                onConfirm={onReturn}
                confirmationText="Are you sure you want this order to be fully returned?"
              >
                Return
              </ConfirmableButton>
            </Space>
          </div>
        </>
      )}
    </>
  );
});
