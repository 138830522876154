import React, { FC } from 'react';
import { Button, Card } from 'antd';

import { ChargeRequest, ChargeRequestStatus } from 'dataTypes';
import LogoIcon from 'images/Logo.svg';
import { usePerformCharge } from 'pages/OrderDetails/status-views/PaymentOverdueStatusView/userPerformCharge';
import { DetailsItemBlock } from 'pages/OrderDetails/components/DetailsItemBlock';
import { OrderItemList } from 'pages/OrderDetails/components/OrderItemList';
import { getShortCurrencyString } from 'utils';

import styles from './ChargeRequestView.module.scss';

interface IChargeRequestView {
  title: string;
  chargeRequest: ChargeRequest;
  showChargeButton?: boolean;
}

const ChargeRequestView: FC<IChargeRequestView> = ({
  title,
  chargeRequest,
  showChargeButton = false,
}) => {
  const { status, totals } = chargeRequest;
  const { loading, execute } = usePerformCharge();

  return (
    <>
      <DetailsItemBlock title="Items" className={styles.items1}>
        <OrderItemList items={chargeRequest.items} />
      </DetailsItemBlock>

      <Card className={styles.host}>
        {status === ChargeRequestStatus.PaymentFailed && (
          <div className={styles.paymentFailedBadge}>Payment Failed</div>
        )}
        <div className={styles.hostTitle}>{title}</div>
        <div className={styles.amountWrapper}>
          <span>Charge Amount</span>
          <strong>{getShortCurrencyString(totals.chargeAmount)}</strong>
        </div>
        <div className={styles.sliderInfo}>
          <div className={styles.sliderInfoBlock}>
            <div className={styles.sliderInfoLine}>
              <span>Cost Price</span>
              <strong>{getShortCurrencyString(totals.costPrice)}</strong>
            </div>
            <div className={styles.sliderInfoLine}>
              <span>Full Price</span>
              <strong>{getShortCurrencyString(totals.fullPrice)}</strong>
            </div>
          </div>
          <div className={styles.sliderInfoBlock}>
            <div className={styles.sliderInfoLine}>
              <span>Discount</span>
              <strong>{`${totals.discountPercentage}% Off`}</strong>
            </div>
            <div className={styles.sliderInfoLine}>
              <span>Savings</span>
              <strong>{getShortCurrencyString(totals.discountAmount)}</strong>
            </div>
          </div>
        </div>
      </Card>
      {showChargeButton && (
        <div className={styles.buttonWrapper}>
          <Button
            type="primary"
            shape="round"
            size="large"
            className={styles.button}
            disabled={loading}
            onClick={execute}
          >
            <img src={LogoIcon} alt="" />
            <span>Charge</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default ChargeRequestView;
