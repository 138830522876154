import { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import {
  LoadingStatuses,
  Order,
  OrderStatus,
  PaymentResult,
  PaymentResultStatus,
  Purchase,
  UploadingError,
} from '../../../dataTypes';
import { ApiService, OrderResponse } from '../../../services';
import { OrderApiService } from '../../../services/OrderApiService';
import { getEnumKeyByValue, mapResponseToOrder } from '../../../utils';

export interface Callbacks {
  onError?(order: Order): Promise<void> | void;
  onErrorWithMessage?(order: Order, message: string): Promise<void> | void;
  onSuccess?(order: Order): Promise<void> | void;
}

export interface OrderFetchable {
  fetchOrder(id: string): unknown;
}

function isAxiosError<T>(e: unknown): e is AxiosError<T> {
  return (e as AxiosError).isAxiosError;
}

@singleton()
export class OrderStore implements OrderFetchable {
  currentOrder!: Order;
  loadingStatus = LoadingStatuses.Init;

  public get isOrderItemsLoaded(): boolean {
    return !!this.currentOrder.items?.length;
  }

  public get isReady(): boolean {
    return this.loadingStatus === LoadingStatuses.Finished;
  }

  constructor(private apiService: ApiService, private orderApiService: OrderApiService) {
    makeAutoObservable(this);
  }

  fetchOrder = async (id: string): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    const orderResponse = await this.apiService.getOrder(id);
    this.setOrder(mapResponseToOrder(orderResponse));

    this.setLoadingStatus(LoadingStatuses.Finished);
  };

  private setOrder(order: Order): void {
    this.currentOrder = order;
  }

  setLoadingStatus(status: LoadingStatuses): void {
    this.loadingStatus = status;
  }

  submitPurchase = async (purchase: Purchase): Promise<void> => {
    try {
      this.setLoadingStatus(LoadingStatuses.InProgress);
      const result = await this.apiService.postPurchase(this.currentOrder.id, purchase);
      this.setOrder(mapResponseToOrder(result));
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  updateOrder = async (order: Order, callbacks?: Callbacks): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);
    const newOrder = this.prepareData(order);

    try {
      const updatedOrder = await this.apiService.putOrder(newOrder);
      this.setOrder(mapResponseToOrder(updatedOrder));
      callbacks?.onSuccess?.(this.currentOrder);
    } catch (e) {
      callbacks?.onError?.(this.currentOrder);
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  private setOrderStatus = async (
    apiCallback: (id: Order['id']) => Promise<OrderResponse>,
  ): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      const orderResponse = await apiCallback(this.currentOrder.id);
      this.setOrder(mapResponseToOrder(orderResponse));
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  setOrderDispatched = async (maxDiscount: number): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.putOrderDispatch(id, 0, maxDiscount));

  setOrderDelivered = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.putOrderDelivered(id));

  setOrderReturned = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.putOrderReturn(id));

  completeOrder = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.putCompleteOrder(id));

  archiveOrder = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.archiveOrder(id));

  unarchiveOrder = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.unarchiveOrder(id));

  setOrderCancelled = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.putOrderCancel(id));

  setOrderReadyForPicking = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.setOrderReadyForPicking(id));

  revertOrderToSubmitted = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.apiService.revertOrderToSubmitted(id));

  revertOrderToDelivered = async (): Promise<void> =>
    await this.setOrderStatus((id: string) => this.orderApiService.revertOrderToDelivered(id));

  takeDeposit = async (paymentMethodId: string): Promise<PaymentResult> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      const result = await this.apiService.takeDeposit(
        this.currentOrder.id,
        paymentMethodId,
        this.currentOrder.customerProfile.id,
      );

      result.status === PaymentResultStatus.Completed &&
        (await this.fetchOrder(this.currentOrder.id));

      return result;
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  captureDeposit = async (): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      await this.apiService.captureDeposit(this.currentOrder.id);

      await this.fetchOrder(this.currentOrder.id);
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  requestCharge = async (chargeAmount: number): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      const orderResponse = await this.orderApiService.requestCharge(
        this.currentOrder.id,
        chargeAmount,
      );
      this.setOrder(mapResponseToOrder(orderResponse));
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  performCharge = async (): Promise<void> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      const orderResponse = await this.orderApiService.performCharge(this.currentOrder.id);
      this.setOrder(mapResponseToOrder(orderResponse));
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  takePayment = async (paymentMethodId: string): Promise<PaymentResult> => {
    this.setLoadingStatus(LoadingStatuses.InProgress);

    try {
      const result = await this.apiService.postTakePayment(
        this.currentOrder.id,
        paymentMethodId,
        this.currentOrder.customerProfile.id,
      );

      result.status === PaymentResultStatus.Completed &&
        (await this.fetchOrder(this.currentOrder.id));

      return result;
    } finally {
      this.setLoadingStatus(LoadingStatuses.Finished);
    }
  };

  uploadItemsFile = async (id: string, file: File, callback?: Callbacks): Promise<void> => {
    let result: OrderResponse;

    try {
      result = await this.apiService.postOrderItemsFile({
        id,
        file,
      });
      this.setOrder(mapResponseToOrder(result));
      callback?.onSuccess?.(this.currentOrder);
    } catch (e) {
      if (isAxiosError<UploadingError>(e)) {
        if (e.response?.data.errorMessage) {
          for (const errorMessage of e.response.data.errorMessage) {
            callback?.onErrorWithMessage?.(this.currentOrder, errorMessage);
          }
          return;
        }
      }
      callback?.onError?.(this.currentOrder);
    }
  };

  private get id(): string {
    return this.currentOrder.id;
  }

  private prepareData(inputOrder: Order): Order {
    const stylingFor = inputOrder.stylingProfile.gender === 'Male' ? 'men' : 'kids';

    return {
      ...inputOrder,
      stylingProfile: {
        ...inputOrder.stylingProfile,
      },
      id: this.id,
      stylingFor,
      status: getEnumKeyByValue(OrderStatus, inputOrder.status) as OrderStatus,
    };
  }
}
