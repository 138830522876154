import React, { useCallback, useContext } from 'react';
import { FC, PropsWithChildren } from 'react';
import { container, InjectionToken } from 'tsyringe';

/* istanbul ignore file */

export const DIContext = React.createContext(container);

export const DIProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <DIContext.Provider value={container}>{children}</DIContext.Provider>
);

export const useInject = <T extends unknown>(token: InjectionToken<T>): T => {
  const instance = useContext(DIContext).resolve<T>(token);
  return useCallback(() => {
    return instance;
  }, [instance])();
};
