import { FormItemProps } from 'antd/lib/form';
import { noAngleBracketsRegex, phoneRegex, postcodeRegex } from '../../../../constants';

export enum PaymentMethodFormFields {
  CARDHOLDER_NAME = 'CARDHOLDER_NAME',
  ADDRESS_FIRSTNAME = 'ADDRESS_FIRSTNAME',
  ADDRESS_LASTNAME = 'ADDRESS_LASTNAME',
  ADDRESS_PHONE = 'ADDRESS_PHONE',
  ADDRESS_HOUSE = 'ADDRESS_HOUSE',
  ADDRESS_STREET = 'ADDRESS_STREET',
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADDRESS_COUNTY = 'ADDRESS_COUNTY',
  ADDRESS_POSTCODE = 'ADDRESS_POSTCODE',
}

interface Options {
  options?: { [key: string]: string };
}

const requiredRule = {
  required: true,
  message: 'Should not be empty',
};

const phoneLengthRule = {
  message: 'Must be between 9 and 20 symbols',
  min: 9,
  max: 20,
};

const phoneRegexRule = {
  message: 'Must be valid phone number',
  pattern: phoneRegex,
};

const postcodeLengthRule = {
  message: 'Must be between 5 and 8 symbols',
  min: 5,
  max: 8,
};

const postcodeRegexRule = {
  message: 'Must be valid UK postcode',
  pattern: postcodeRegex,
};

const noAngleBracketsRegexRule = {
  message: 'Must not include < and > symbols',
  pattern: noAngleBracketsRegex,
};

export const paymentMethodFormConfig: {
  [key in PaymentMethodFormFields]: FormItemProps & Options;
} = {
  [PaymentMethodFormFields.CARDHOLDER_NAME]: {
    label: 'Cardholder name',
    name: ['card', 'cardholderName'],
    rules: [
      requiredRule,
      {
        message: 'Cannot be longer than 35 symbols',
        min: 1,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_FIRSTNAME]: {
    label: 'First name',
    name: ['billingAddress', 'firstName'],
    rules: [
      requiredRule,
      {
        message: 'Cannot be longer than 35 symbols',
        min: 1,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_LASTNAME]: {
    label: 'Last name',
    name: ['billingAddress', 'lastName'],
    rules: [
      requiredRule,
      {
        message: 'Cannot be longer than 35 symbols',
        min: 1,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_PHONE]: {
    label: 'Phone',
    name: ['billingAddress', 'phone'],
    rules: [requiredRule, phoneLengthRule, phoneRegexRule],
  },
  [PaymentMethodFormFields.ADDRESS_HOUSE]: {
    label: 'House name / No',
    name: ['billingAddress', 'house'],
    rules: [
      requiredRule,
      {
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_STREET]: {
    label: 'Street name',
    name: ['billingAddress', 'street'],
    rules: [
      requiredRule,
      {
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_CITY]: {
    label: 'City',
    name: ['billingAddress', 'city'],
    rules: [
      requiredRule,
      {
        min: 2,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_COUNTY]: {
    label: 'County',
    name: ['billingAddress', 'county'],
    rules: [
      {
        max: 32,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [PaymentMethodFormFields.ADDRESS_POSTCODE]: {
    label: 'Postcode',
    name: ['billingAddress', 'postcode'],
    rules: [requiredRule, postcodeRegexRule, postcodeLengthRule],
  },
};
