import notification from 'antd/lib/notification';
import {
  CAPTURE_DEPOSIT_ERROR_MESSAGE,
  CAPTURE_DEPOSIT_ERROR_DESCRIPTION,
  CAPTURE_DEPOSIT_SUCCESS_MESSAGE,
  CAPTURE_DEPOSIT_SUCCESS_DESCRIPTION,
} from '../../consts';

export const showCaptureDepositErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: CAPTURE_DEPOSIT_ERROR_MESSAGE,
    description: CAPTURE_DEPOSIT_ERROR_DESCRIPTION,
  });
};

export const showCaptureDepositSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: CAPTURE_DEPOSIT_SUCCESS_MESSAGE,
    description: CAPTURE_DEPOSIT_SUCCESS_DESCRIPTION,
  });
};
