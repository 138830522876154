/* istanbul ignore file */
import React, { FC, useEffect, useState } from 'react';
import { Button, Select } from 'antd';
import styles from './StatusSelect.module.scss';
import { OrderStatus } from '../../../../dataTypes';
import { getEnumKeyByValue } from '../../../../utils';

interface Props {
  statuses: string[];
  onChange(newStatuses: string[]): void;
}

export const StatusSelect: FC<Props> = (props) => {
  const { statuses, onChange } = props;

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(statuses);

  useEffect(() => {
    setSelectedStatuses(statuses);
  }, [statuses]);

  return (
    <div className={styles.container}>
      <Select
        mode="multiple"
        value={selectedStatuses}
        onChange={setSelectedStatuses}
        className={styles.statusSelect}
      >
        {Object.values(OrderStatus).map((status) => (
          <Select.Option key={status} value={getEnumKeyByValue(OrderStatus, status)}>
            {status}
          </Select.Option>
        ))}
      </Select>
      <Button disabled={selectedStatuses === statuses} onClick={() => onChange(selectedStatuses)}>
        Apply
      </Button>
    </div>
  );
};
