import React, { FC } from 'react';
import { CodeSandboxOutlined } from '@ant-design/icons';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';
import { useSetDelivered } from './useSetDelivered';

export const DeliveredOrderButton: FC = () => {
  const { execute, loading } = useSetDelivered();

  return (
    <ConfirmableButton onConfirm={execute} disabled={loading}>
      <CodeSandboxOutlined />
      Mark Delivered
    </ConfirmableButton>
  );
};
