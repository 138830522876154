import { CategoryOptions } from './categories';
import { DatePicker } from '../../components/form/DatePicker';
import { DetailsItemBlock } from '../../components/DetailsItemBlock';
import { PageLayout } from '../../components/PageLayout';
import { FC, useMemo } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { FormProps } from 'antd/lib/form';
import { FormValue } from '../../components/form/FormValue';
import { FormatText } from '../../components/form/FormatText';
import { NO_COMMENTS_PROVIDED } from '../../../../consts';
import { QuestionnaireFormItem } from '../../components/form/QuestionnaireFormItem';
import { QuestionnaireItem, OrderStatus, SystemOfMeasure } from '../../../../dataTypes';
import { OrderFormFields as Fields, orderFormConfig as cfg } from './orderFormConfig';
import { OrderStore } from '../../stores';
import { useGetGoToDetailsCallback } from '../../hooks/useGetGoToDetailsCallback';
import { useInject } from '../../../../providers/DIProvider';
import { ButtonWrapper } from '../../../../components/ButtonWrapper/ButtonWrapper';
import { useOnFinish } from './useOnFinish';
import { GoBackButton } from '../../components/GoBackButton';
import { OrderLayoutGrid } from '../../components/OrderLayoutGrid';
import styles from './OrderEditForm.module.scss';
import { generateWeightRule } from './weightRules';
import { generateHeightRule } from './heightRules';
import { kidsQuestions } from 'kidsQuestions';
import { menQuestions } from 'menQuestions';

const { TextArea } = Input;
const { Option } = Select;

export const formProps: FormProps = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
  hideRequiredMark: true,
  labelAlign: 'left',
  size: 'small',
};

export const OrderEditForm: FC = () => {
  const { currentOrder } = useInject(OrderStore);
  const { stylingProfile } = currentOrder;

  const goToDetailsPage = useGetGoToDetailsCallback();
  const onFinish = useOnFinish();

  const title = `${currentOrder.id} - ${stylingProfile.firstName} ${stylingProfile.lastName}`;

  const questions = useMemo(
    () => (currentOrder.stylingFor === 'men' ? menQuestions : kidsQuestions),
    [currentOrder.stylingFor],
  );

  const getAnswerOptions = (label: string): string[] => {
    const question = questions.find((el) => el.label === label);
    return question ? question.options : [];
  };

  const deserializeFromValue =
    (question: string) =>
    (value: string): QuestionnaireItem => {
      return {
        question,
        answers: [value],
      };
    };

  const serializeToValue = (value: QuestionnaireItem): { value: string } => {
    return {
      value: value.answers[0],
    };
  };

  return (
    <PageLayout
      title={title}
      status={currentOrder.status}
      buttons={<GoBackButton />}
      salesforceLink={currentOrder.salesforceOpportunityLink}
    >
      <Form {...formProps} initialValues={currentOrder} onFinish={onFinish}>
        <OrderLayoutGrid>
          <DetailsItemBlock title={'Status'}>
            <Form.Item {...cfg[Fields.STATUS]}>
              <Select className={styles.status}>
                {Object.values(OrderStatus).map((status) => (
                  <Option key={status} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Contact Details'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.PROFILE_FIRSTNAME]}>
              <Input className={styles.formInput} />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_LASTNAME]}>
              <Input className={styles.formInput} />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_PHONE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_EMAIL]}>
              <Input />
            </Form.Item>
            <Form.Item hidden {...cfg[Fields.PROFILE_COMMUNICATION_METHOD]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_METHOD]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_DATE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_TIME]}>
              <Input />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Delivery Address'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.ADDRESS_NAME]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_PHONE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_HOUSE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_STREET]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_CITY]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_COUNTY]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_POSTCODE]}>
              <Input />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Styling for'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.STYLING_FOR_FIRSTNAME]}>
              <Input className={styles.formInput} />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_LASTNAME]}>
              <Input className={styles.formInput} />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_BIRTHDAY]}>
              <DatePicker size={'small'} className={styles.formInput} />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_GENDER]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.INTENTION]}>
              <Input className={styles.formInput} />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Categories'} className={styles.test}>
            <Form.Item {...cfg.CATEGORIES}>
              <Select mode="multiple">{CategoryOptions}</Select>
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Size & fit'} className={styles.editCustomerProfile}>
            {currentOrder.stylingProfile.questionnaire.map((item: QuestionnaireItem, key) => {
              if (item.question === 'Height') {
                return (
                  <QuestionnaireFormItem
                    item={item}
                    key={key}
                    name={['stylingProfile', 'questionnaire', key]}
                    rules={[
                      generateHeightRule(
                        currentOrder.stylingProfile.systemOfMeasure ?? SystemOfMeasure.Metric,
                      ),
                    ]}
                  >
                    <Input />
                  </QuestionnaireFormItem>
                );
              }
              if (item.question === 'Weight') {
                return (
                  <QuestionnaireFormItem
                    item={item}
                    key={key}
                    name={['stylingProfile', 'questionnaire', key]}
                    rules={[
                      generateWeightRule(
                        currentOrder.stylingProfile.systemOfMeasure ?? SystemOfMeasure.Metric,
                      ),
                    ]}
                  >
                    <Input />
                  </QuestionnaireFormItem>
                );
              }
              return (
                <Form.Item
                  key={key}
                  label={item.question}
                  name={['stylingProfile', 'questionnaire', key]}
                  getValueFromEvent={deserializeFromValue(item.question)}
                  getValueProps={serializeToValue}
                >
                  <Select className={styles.status}>
                    {getAnswerOptions(item.question).map((el) => (
                      <Option key={el} value={el}>
                        {el}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            })}
            <Form.Item label="System of measure" name={['stylingProfile', 'systemOfMeasure']}>
              <FormValue />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Size & Fit Comments'}>
            <FormatText
              value={currentOrder.stylingProfile.questionnaireComments}
              placeholder={NO_COMMENTS_PROVIDED}
            />
          </DetailsItemBlock>
          <DetailsItemBlock title={'Customer Comments'}>
            <FormatText value={currentOrder.customerComments} placeholder={NO_COMMENTS_PROVIDED} />
          </DetailsItemBlock>
          <DetailsItemBlock title={'Stylist Comments'}>
            <Form.Item {...cfg.STYLIST_COMMENTS}>
              <TextArea rows={10} />
            </Form.Item>
          </DetailsItemBlock>
        </OrderLayoutGrid>
        <ButtonWrapper>
          <Space className={styles.buttonWrapper}>
            <Button onClick={goToDetailsPage} type="default" shape="round" size="large">
              Cancel
            </Button>
            <Button type="primary" shape="round" size="large" htmlType="submit">
              Save
            </Button>
          </Space>
        </ButtonWrapper>
      </Form>
    </PageLayout>
  );
};
