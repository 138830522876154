import React from 'react';
import { FC } from 'react';
import { OrderStatusBadge } from '../../../../components/OrderStatusBadge';
import { OrderStatus } from '../../../../dataTypes';
import styles from './StatusBreadcrumbs.module.scss';

interface Props {
  status: OrderStatus;
}

export const StatusBreadcrumbs: FC<Props> = ({ status }: Props) => (
  <div className={styles.statusBreadcrumbs}>
    <OrderStatusBadge status={status} />
  </div>
);
