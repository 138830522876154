import './App.css';
import './styles/ant.css';
import React, { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { InitLoadingComponent } from './components/InitLoadingComponent';
import { WithSetAddAccessAndIdKeyToEachRequest } from './components/smart/WithSetAddAccessAndIdKeyToEachRequest';
import { WithSetHttpErrorCallback } from './components/smart/WithSetHttpErrorCallback';
import ConfigProvider from 'providers/ConfigProvider';
import ProtectedArea from 'components/ProtectedArea';
import { Redirect, Route, Switch } from 'react-router';
import { ContainerComponent } from 'components';
import { OrderDetails } from 'pages/OrderDetails';
import { OrderReceipt } from 'pages/OrderReceipt';
import {
  INVOICE_URL,
  ORDER_DETAILS_URL,
  ORDER_LIST_URL,
  ORDER_RECEIPT_URL,
  ROOT_URL,
} from 'consts';
import { ContentWrapperComponent } from 'components/ContentWrapper';
import { OrderList } from 'pages/OrderList';
import { Invoice } from 'pages/Invoice';
import { HelmetProvider } from 'react-helmet-async';

export const App: FC = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <InitLoadingComponent />;
  }

  const OrderRouteComponent: FC = () => (
    <ContainerComponent>
      <OrderDetails />
    </ContainerComponent>
  );

  return (
    <WithSetAddAccessAndIdKeyToEachRequest>
      <WithSetHttpErrorCallback>
        <ProtectedArea>
          <ConfigProvider>
            <HelmetProvider>
              <Switch>
                <Route component={() => <Redirect to={ORDER_LIST_URL} />} exact path={ROOT_URL} />
                <Route component={OrderReceipt} path={ORDER_RECEIPT_URL} />
                <Route component={Invoice} path={INVOICE_URL} />
                <Route
                  path={ORDER_LIST_URL}
                  component={() => (
                    <ContentWrapperComponent>
                      <Switch>
                        <Route component={OrderList} exact path={ORDER_LIST_URL} />
                        <Route component={OrderRouteComponent} exact path={ORDER_DETAILS_URL} />
                      </Switch>
                    </ContentWrapperComponent>
                  )}
                />
              </Switch>
            </HelmetProvider>
          </ConfigProvider>
        </ProtectedArea>
      </WithSetHttpErrorCallback>
    </WithSetAddAccessAndIdKeyToEachRequest>
  );
};
