import React, { FC } from 'react';
import { Card } from 'antd';
import styles from './PriceInfo.module.scss';
import { getShortCurrencyString } from '../../../../../../utils';

interface Props {
  sellPrice: number;
  discount: number;
}

export const PriceInfo: FC<Props> = ({ sellPrice, discount }) => {
  return (
    <Card>
      <div className={styles.info}>
        <div className={styles.infoPrice} data-testid={'sell-price'}>
          {getShortCurrencyString(sellPrice)}
        </div>
        <div className={styles.infoBox} data-testid={'discount'}>
          <div className={styles.infoBoxText}>{discount}%</div>
          <div className={styles.infoBoxText}>Off</div>
        </div>
      </div>
    </Card>
  );
};
