import notification from 'antd/lib/notification';
import { RETURN_ERROR_DESCRIPTION, RETURN_ERROR_MESSAGE } from '../../consts';

/* istanbul ignore file */

export const showReturnErrorMessage = (
  errorDescription: string = RETURN_ERROR_DESCRIPTION,
): void => {
  notification.error({
    message: RETURN_ERROR_MESSAGE,
    description: errorDescription,
  });
};
