import { Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import { TagComponent, TagType } from '../../../../../components/TagComponent';

import styles from './FormatCategories.module.scss';

export const NO_CATEGORY_MESSAGE = 'There are no categories';

dayjs.extend(localizedFormat);

interface Props {
  value?: string[];
}

export const FormatCategories: React.FC<Props> = ({ value = [] }) => {
  if (!value.length) {
    return <Typography.Text type="secondary">{NO_CATEGORY_MESSAGE}</Typography.Text>;
  }
  return (
    <>
      {value.map((t) => (
        <TagComponent className={styles.tag} type={TagType.small} key={t}>
          {t}
        </TagComponent>
      ))}
    </>
  );
};
