import { Space } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { FC } from 'react';
import { SubHeader } from '../SubHeader';

type Props = PropsWithChildren<unknown> & { title: ReactNode; className?: string };

export const DetailsItemBlock: FC<Props> = ({ children, title, className }: Props) => (
  <Space size={'large'} direction="vertical" className={className}>
    <SubHeader>{title}</SubHeader>
    <div>{children}</div>
  </Space>
);
