import notification from 'antd/lib/notification';
import {
  PERFORM_CHARGE_SUCCESS_MESSAGE,
  PERFORM_CHARGE_SUCCESS_DESCRIPTION,
  PERFORM_CHARGE_ERROR_MESSAGE,
  PERFORM_CHARGE_ERROR_DESCRIPTION,
} from '../../consts';

export const showPerformChargeErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: PERFORM_CHARGE_ERROR_MESSAGE,
    description: PERFORM_CHARGE_ERROR_DESCRIPTION,
  });
};

export const showPerformChargeSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: PERFORM_CHARGE_SUCCESS_MESSAGE,
    description: PERFORM_CHARGE_SUCCESS_DESCRIPTION,
  });
};
