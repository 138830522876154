import React, { FC } from 'react';
import { OrderItem } from '../../../../../dataTypes';
import styles from './OrderItemDetails.module.scss';

interface Props {
  item: OrderItem;
}

export const OrderItemDetails: FC<Props> = ({ item }: Props) => (
  <table className={styles.container}>
    <tbody>
      <tr>
        <th>Warehouse category</th>
        <td>{item.category}</td>
      </tr>
      <tr>
        <th>Ean</th>
        <td>{item.ean}</td>
      </tr>
      <tr>
        <th>Hs Code</th>
        <td>{item.hsCode}</td>
      </tr>
      <tr>
        <th>Division</th>
        <td>{item.division}</td>
      </tr>
      <tr>
        <th>Fabric Comp</th>
        <td>{item.fabricComposition}</td>
      </tr>
      <tr>
        <th>Origin</th>
        <td>{item.countryOfOrigin}</td>
      </tr>
      <tr>
        <th>Weight</th>
        <td>{item.weight}</td>
      </tr>
    </tbody>
  </table>
);
