import React, { FC, useState } from 'react';
import { PurchasedOrder } from '../../../../dataTypes';
import { useOrderStore } from '../../hooks/useOrderStore';
import { PurchaseView } from '../../components/PurchaseView';
import { Button, Space } from 'antd';
import LogoIcon from '../../../../images/Logo.svg';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';
import { PrinterOutlined, StopOutlined } from '@ant-design/icons';
import { useRevertOrderToDelivered } from './userRevertOrderToDelivered';

import styles from './PendingPaymentStatusView.module.scss';
import ChargeRequestCreate from 'pages/OrderDetails/components/ChargeRequestCreate';
import useConfig from 'hooks/useConfig';
import { TakePaymentButton } from 'pages/OrderDetails/components/TakePaymentButton/TakePaymentButton';
import { generatePath } from 'react-router-dom';
import { INVOICE_URL } from 'consts';
import { CaptureDepositButton } from 'pages/OrderDetails/components/CaptureDepositButton';
import { CompleteOrderButton } from 'pages/OrderDetails/components/CompleteOrderButton';

export const PendingPaymentStatusView: FC = () => {
  const { loading, execute } = useRevertOrderToDelivered();

  const { currentOrder } = useOrderStore();
  const depositAvailable =
    currentOrder.deposits &&
    currentOrder.deposits.filter((el) => el.status === 'Active').length > 0;
  const purchasedOrder = currentOrder as PurchasedOrder;

  const [showRequestCharge, setShowRequestCharge] = useState(false);

  const { config } = useConfig();

  const captureDepositButton = config?.allowCaptureDeposit ? <CaptureDepositButton /> : null;

  return showRequestCharge ? (
    <ChargeRequestCreate onCancel={() => setShowRequestCharge(false)} />
  ) : (
    <>
      <div className={styles.buttonsContainer}>
        <Space>
          <Button
            type={'primary'}
            shape={'round'}
            size={'large'}
            href={generatePath(INVOICE_URL, { id: currentOrder.id })}
            target={'_blank'}
          >
            <PrinterOutlined />
            Invoice
          </Button>
          {depositAvailable ? (
            captureDepositButton
          ) : (
            <>
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={() => setShowRequestCharge(true)}
                className={styles.buttonRequestCharge}
              >
                <img src={LogoIcon} alt="" />
                <span>Request Charge</span>
              </Button>
              <CompleteOrderButton />
            </>
          )}
        </Space>
        <Space>
          <ConfirmableButton
            disabled={loading}
            onConfirm={execute}
            confirmationText="Are you sure you want to revert this order to Delivered status to amend agreement? Current purchase and return agreements will be lost."
          >
            <StopOutlined />
            Amend Agreement
          </ConfirmableButton>
          {config?.allowTakePayment && <TakePaymentButton />}
        </Space>
      </div>
      <PurchaseView purchase={purchasedOrder.purchase} />
    </>
  );
};
