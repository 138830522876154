import { singleton } from 'tsyringe';
import { OrderStore } from './index';
import { makeAutoObservable, runInAction } from 'mobx';
import { ShipmentApiService } from '../../../services/Shipment/ShipmentApiService';
import { LoadingStatuses } from '../../../dataTypes';

@singleton()
export class ShippingStore {
  public get isShipmentBooked(): boolean {
    if (!this.isReady) {
      throw new Error('Store is not ready');
    }
    return this._isShipmentBooked;
  }

  public get isAbleToBookShipment(): boolean {
    this._checkOrderStoreIsReady();
    return this.orderStore.isOrderItemsLoaded;
  }

  private _shippingBookingStatus = LoadingStatuses.Init;
  private _isShipmentBooked = false;

  public get isReady(): boolean {
    return this._shippingBookingStatus === LoadingStatuses.Finished && this.orderStore.isReady;
  }

  public get hasError(): boolean {
    return this._shippingBookingStatus === LoadingStatuses.Error;
  }

  private _checkOrderStoreIsReady(): void {
    if (!this.orderStore.isReady) {
      throw new Error('OrderStore is not ready');
    }
  }

  public initialize(): Promise<void> {
    return this._fetchShippingStatus();
  }

  private async _fetchShippingStatus(): Promise<void> {
    this._checkOrderStoreIsReady();
    try {
      this._shippingBookingStatus = LoadingStatuses.InProgress;
      this._isShipmentBooked = await this.api.fetchShipmentStatus(this.orderStore.currentOrder.id);
      this._shippingBookingStatus = LoadingStatuses.Finished;
    } catch (e) {
      this._shippingBookingStatus = LoadingStatuses.Error;
      throw e;
    }
  }

  constructor(private orderStore: OrderStore, private api: ShipmentApiService) {
    makeAutoObservable(this);
  }

  /* istanbul ignore next */
  private get orderId(): string {
    return this.orderStore.currentOrder.id;
  }

  public async postShipment(): Promise<void> {
    await this.api.postShipment(this.orderId);
    runInAction(() => {
      this._isShipmentBooked = true;
    });
  }

  /* istanbul ignore next */
  public print(): Promise<void> {
    return this.api.getShipmentLabel(this.orderId);
  }
}
