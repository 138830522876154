import { ORDER_DETAILS_URL } from '../../../consts/urls';
import { useGetOrderId } from './useGetOrderId';
import { generatePath, useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const useGetGoToPaymentMethodCallback = (): (() => void) => {
  const id = useGetOrderId();
  const history = useHistory();
  return useCallback((): void => {
    history.replace(generatePath(ORDER_DETAILS_URL, { id, state: 'payment-method' }));
  }, [history, id]);
};
