import { useQuery, UseQueryResult } from 'react-query';
import { useInject } from '../../../providers/DIProvider';
import { ShipmentApiService } from '../../../services/Shipment/ShipmentApiService';
import { ShipmentResponse } from '../../../services/Shipment/ShipmentResponse';

/* istanbul ignore file */

export function useOrderShipment(orderId: string): UseQueryResult<ShipmentResponse> {
  const apiService = useInject(ShipmentApiService);

  return useQuery(['shipment', orderId], async () => {
    return await apiService.getShipmentInfo(orderId);
  });
}
