import { useQuery, UseQueryResult } from 'react-query';
import { Order } from '../../../dataTypes';
import { useInject } from '../../../providers/DIProvider';
import { ApiService } from '../../../services';
import { mapResponseToOrder } from '../../../utils';

export function useOrderDetails(orderId: Order['id']): UseQueryResult<Order> {
  const apiService = useInject(ApiService);

  return useQuery(['order', orderId], async () => {
    const orderResponse = await apiService.getOrder(orderId);
    return mapResponseToOrder(orderResponse);
  });
}
