import { usePermissions } from 'hooks/usePermissions';
import React, { useEffect, useState } from 'react';

interface Config {
  showChargeButton: boolean;
  allowPurchaseBelowMinimalPrice: boolean;
  allowArchiveOrders: boolean;
  allowUnarchiveOrders: boolean;
  allowTakePayment: boolean;
  allowSetReadyForPicking: boolean;
  allowRevertToSubmitted: boolean;
  allowTakeDeposit: boolean;
  allowCaptureDeposit: boolean;
}

export interface IConfigContext {
  config: Config | null;
}

const defaultConfig = {
  showChargeButton: false,
  allowPurchaseBelowMinimalPrice: false,
  allowArchiveOrders: false,
  allowUnarchiveOrders: false,
  allowTakePayment: false,
  allowSetReadyForPicking: false,
  allowRevertToSubmitted: false,
  allowTakeDeposit: false,
  allowCaptureDeposit: false,
};

const ConfigContext = React.createContext<IConfigContext>({
  config: defaultConfig,
});

const ConfigProvider: React.FC = ({ children }) => {
  const { data } = usePermissions();

  const [config, setConfigData] = useState<Config | null>(defaultConfig);

  useEffect(() => {
    if (data) {
      const newConfig = {
        showChargeButton: data.indexOf('perform-charges') > -1,
        allowPurchaseBelowMinimalPrice: data.indexOf('set-price-below-min') > -1,
        allowArchiveOrders: data.indexOf('archive-orders') > -1,
        allowUnarchiveOrders: data.indexOf('unarchive-orders') > -1,
        allowTakePayment: data.indexOf('take-payments') > -1,
        allowSetReadyForPicking: data.indexOf('set-ready-for-picking') > -1,
        allowRevertToSubmitted: data.indexOf('revert-to-submitted') > -1,
        allowTakeDeposit: data.indexOf('take-deposits') > -1,
        allowCaptureDeposit: data.indexOf('capture-deposits') > -1,
      };

      setConfigData(newConfig);
    }
  }, [data]);

  return <ConfigContext.Provider value={{ config }}>{children}</ConfigContext.Provider>;
};

export { ConfigContext };
export default ConfigProvider;
