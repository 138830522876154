import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { SendShipment } from '../../../../components/icons/SendShipment';
import { useInject } from '../../../../providers/DIProvider';
import { ShippingStore } from '../../stores/ShippingStore';
import { useDispatchOrder } from './useDispatchOrder';
import { observer } from 'mobx-react-lite';

interface Props {
  maxDiscount: number;
}

export const DispatchOrderButton: FC<Props> = observer(({ maxDiscount }: Props) => {
  const shippingStore = useInject(ShippingStore);

  const makeDispatched = useDispatchOrder();

  if (!shippingStore.isReady || !shippingStore.isAbleToBookShipment) {
    return null;
  }

  return (
    <Tooltip
      popupVisible={true}
      destroyTooltipOnHide={true}
      defaultVisible={false}
      title="Please print a label first"
      visible={shippingStore.isShipmentBooked ? false : undefined}
    >
      <Button
        data-testid={'dispatch'}
        disabled={!shippingStore.isShipmentBooked}
        onClick={async () => await makeDispatched.execute(maxDiscount)}
        size={'large'}
        type={'primary'}
        shape={'round'}
      >
        <SendShipment />
        {!makeDispatched.loading ? 'Set Dispatched' : 'Loading...'}
      </Button>
    </Tooltip>
  );
});
