import { FC, useState } from 'react';
import styles from './DeliveredStatusView.module.scss';
import { observer } from 'mobx-react-lite';
import { PriceSelector } from './components/PriceSelector';
import ChargeRequestCreate from 'pages/OrderDetails/components/ChargeRequestCreate';
import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { generatePath } from 'react-router-dom';
import { INVOICE_URL } from 'consts';
import { useOrderStore } from 'pages/OrderDetails/hooks/useOrderStore';

export const DeliveredStatusView: FC = observer(() => {
  const [showPriceSelector, setShowPriceSelector] = useState(true);
  const { currentOrder } = useOrderStore();

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Button
          type={'primary'}
          shape={'round'}
          size={'large'}
          href={generatePath(INVOICE_URL, { id: currentOrder.id })}
          target={'_blank'}
        >
          <PrinterOutlined />
          Invoice
        </Button>
      </div>
      {showPriceSelector ? (
        <PriceSelector onRequestCharge={() => setShowPriceSelector(false)} />
      ) : (
        <ChargeRequestCreate onCancel={() => setShowPriceSelector(true)} />
      )}
    </>
  );
});
