import React, { FC } from 'react';
import { Button } from 'antd';
import { useOrderStore } from '../../hooks/useOrderStore';
import { useOrderReturn } from '../../serverCache/useOrderReturn';

/* istanbul ignore file */

export const TrackReturnButton: FC = () => {
  const { currentOrder } = useOrderStore();

  const { data } = useOrderReturn(currentOrder.id);

  return (
    <Button
      type="primary"
      shape="round"
      size="large"
      disabled={!data?.trackingLink}
      href={data?.trackingLink}
      target="_blank"
    >
      Track return
    </Button>
  );
};
