import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import { useOrderStore } from 'pages/OrderDetails/hooks/useOrderStore';
import {
  showCaptureDepositErrorMessage,
  showCaptureDepositSuccessMessage,
} from 'components/Messages/showCaptureDepositMessages';

export function useCaptureDeposit(): UseAsyncReturn<void, []> {
  const store = useOrderStore();

  return useAsyncCallback(async () => {
    try {
      await store.captureDeposit();
      showCaptureDepositSuccessMessage();
    } catch (e) {
      showCaptureDepositErrorMessage();
    }
  });
}
