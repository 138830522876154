import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import { FC } from 'react';
import { NOT_SET } from '../../consts';
import { FormatText } from '../../pages/OrderDetails/components/form/FormatText';
import styles from './HeaderComponent.module.scss';

interface HeaderProps {
  extra?: ReactNode;
  salesforceLink?: string;
  title: string;
}

export const HeaderComponent: FC<HeaderProps> = ({ title, salesforceLink, extra }: HeaderProps) => {
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.orderTitle}>
          <Typography.Title level={1}>
            <FormatText value={title} placeholder={NOT_SET} />
          </Typography.Title>
          {salesforceLink && (
            <a
              href={salesforceLink}
              className={styles.salesforceLink}
              target="_blank"
              rel="noreferrer"
            >
              Open in salesforce
            </a>
          )}
        </div>
        <div>{extra}</div>
      </div>
    </div>
  );
};
