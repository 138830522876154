import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './DepositStatusBadge.module.scss';

interface Props {
  amount?: number;
  amountSpent?: number;
  amountRefunded?: number;
}

export const DepositStatusBadge: FC<Props> = ({ amount, amountSpent, amountRefunded }) => {
  let text: string;
  let className: string;
  if (amount && amount > 0) {
    text = `£${amount} deposit taken`;
    className = classNames(styles.state, styles.depositTaken);
  } else if (amountSpent && amountSpent > 0 && (!amountRefunded || amountRefunded === 0)) {
    text = `£${amountSpent} deposit captured`;
    className = classNames(styles.state, styles.depositCaptured);
  } else if ((!amountSpent || amountSpent === 0) && amountRefunded && amountRefunded > 0) {
    text = `£${amountRefunded} deposit refunded`;
    className = classNames(styles.state, styles.depositRefunded);
  } else if (amountSpent && amountSpent > 0 && amountRefunded && amountRefunded > 0) {
    text = `£${amountSpent} captured, £${amountRefunded} refunded`;
    className = classNames(styles.state, styles.depositRefunded);
  } else {
    text = `No deposit taken`;
    className = classNames(styles.state, styles.noDepositTaken);
  }

  return <div className={className}>{text}</div>;
};
