import React, { FC } from 'react';
import { useOrderStore } from '../../hooks/useOrderStore';
import { useGetChargeRequest } from 'hooks/useGetChargeRequest';
import useConfig from 'hooks/useConfig';
import ChargeRequestView from 'components/ChargeRequestView';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { generatePath } from 'react-router-dom';
import { INVOICE_URL } from 'consts';
import styles from './PaymentOverdueStatusView.module.scss';

export const TITLE_WITH_PERMISSION = 'Please click Charge button below to make a payment';
export const TITLE_WITHOUT_PERMISSION =
  'Please request a person with permission to perform a charge';

export const PaymentOverdueStatusView: FC = () => {
  const { currentOrder } = useOrderStore();
  const { data: chargeRequest, status } = useGetChargeRequest(currentOrder.id);
  const { config } = useConfig();

  if (status === 'loading' || !chargeRequest) {
    return null;
  }

  console.log(config);

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Button
          type={'primary'}
          shape={'round'}
          size={'large'}
          href={generatePath(INVOICE_URL, { id: currentOrder.id })}
          target={'_blank'}
        >
          <PrinterOutlined />
          Invoice
        </Button>
      </div>

      <ChargeRequestView
        title={config?.showChargeButton ? TITLE_WITH_PERMISSION : TITLE_WITHOUT_PERMISSION}
        chargeRequest={chargeRequest}
        showChargeButton={!!config?.showChargeButton}
      />
    </>
  );
};
