import React, { FC } from 'react';
import { InboxOutlined } from '@ant-design/icons/lib/icons';
import { useArchiveOrder } from './useArchiveOrder';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const ArchiveOrderButton: FC = () => {
  const { execute, loading } = useArchiveOrder();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to archive this order?"
    >
      <InboxOutlined />
      Archive
    </ConfirmableButton>
  );
};
