import notification from 'antd/lib/notification';
import {
  ORDER_UPDATE_ERROR_MESSAGE,
  ORDER_UPDATE_ERROR_DESCRIPTION,
  ORDER_UPDATE_SUCCESS_DESCRIPTION,
  ORDER_UPDATE_SUCCESS_MESSAGE,
} from '../../consts';

export const showOrderUpdateErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: ORDER_UPDATE_ERROR_MESSAGE,
    description: ORDER_UPDATE_ERROR_DESCRIPTION,
  });
};

export const showOrderUpdateSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: ORDER_UPDATE_SUCCESS_MESSAGE,
    description: ORDER_UPDATE_SUCCESS_DESCRIPTION,
  });
};
