import { PaymentMethod } from 'dataTypes';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useInject } from '../../../providers/DIProvider';
import { ApiService, PaymentMethodList } from '../../../services';

/* istanbul ignore file */

const queryKey = 'payment-methods';
export function useOrderPaymentMethods(orderId: string): UseQueryResult<PaymentMethodList> {
  const apiService = useInject(ApiService);

  return useQuery(['payment-methods', orderId], async () => {
    return await apiService.getOrderPaymentMethods(orderId);
  });
}

interface UpdateParams {
  id: string;
  payload: Partial<PaymentMethod>;
}

export function useUpdatePaymentMethod(): UseMutationResult<
  PaymentMethod | null,
  unknown,
  UpdateParams
> {
  const apiService = useInject(ApiService);
  const queryClient = useQueryClient();

  return useMutation(
    async (params: UpdateParams) => {
      return await apiService.patchPaymentMethod(params.id, params.payload);
    },
    {
      onSuccess: () => queryClient.invalidateQueries(queryKey),
    },
  );
}
