/* eslint-disable react/no-unescaped-entities */
import React, { FC } from 'react';
import styles from './Invoice.module.scss';
import ChoixceBlack from '../../images/ChoixceBlack.svg';
import { useParams } from 'react-router-dom';
import { useOrderDetails } from 'pages/OrderReceipt/serverCache/useOrderDetails';
import { InitLoadingComponent } from 'components/InitLoadingComponent';
import { OrderItem } from 'dataTypes';

interface RouteParams {
  id: string; // orderId
}

function calculateItemsPrice(items: OrderItem[]): number {
  return items.map((item) => item.choixcePrice).reduce((prev, curr) => prev + curr, 0);
}

export const Invoice: FC = () => {
  const { id: orderId } = useParams<RouteParams>();
  const { data: order, isLoading } = useOrderDetails(orderId);

  let vat: number;

  if (order?.stylingProfile.gender !== 'Male') {
    vat = 0;
  } else {
    vat = 0.2;
  }

  const orderItems = order?.items ?? [];

  if (isLoading) {
    return <InitLoadingComponent />;
  }

  const fullPrice = calculateItemsPrice(orderItems);

  const vatTotal = fullPrice * vat;
  const subTotal = fullPrice * 1 - vat;
  const currentDate = new Date();

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.headerBox}>
          <img src={ChoixceBlack} alt="logo" />
          <div className={styles.headerText}>
            <strong>Invoice Number:</strong> {order?.id} <br />
            <strong>Payment Terms:</strong>
            <span className={styles.headerTextActive}> 14 Days</span>
          </div>
        </div>

        <div className={styles.invoiceTo}>
          <strong>Invoice to:</strong>
          <ul className={styles.list}>
            <li>
              {order?.stylingProfile.firstName} {order?.stylingProfile.lastName}
            </li>
            <li>{order?.address.house}</li>
            <li>{order?.address.street}</li>
            <li>{order?.address.city}</li>
            <li>{order?.address.county}</li>
            <li>{order?.address.postcode}</li>
          </ul>
        </div>
      </div>

      <div className={styles.box}>
        <p>Items now non returnable as per our T&C's</p>
        <p>
          Go to Choixce.com to make a payment or contact your stylist to agree a price you want to
          pay.
        </p>
        <p>
          If we do not hear from you or no payment is made in 14 days, this invoice will be passed
          to our{' '}
        </p>
        <p>debt recovery partners.</p>
      </div>

      <div className={styles.data}>
        <strong>Invoice Date:</strong> {currentDate.toLocaleDateString()}
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <td className={styles.theadTd}>Product Description</td>
            <td className={styles.theadTd}>Qty</td>
            <td className={styles.theadTd}>Unit Cost</td>
            <td className={styles.theadTd}>Total Cost</td>
            <td className={styles.theadTd}>VAT</td>
            <td className={styles.theadTd}>Cost inc VAT</td>
          </tr>
        </thead>

        <tbody className={styles.tbody}>
          {orderItems.map((item) => (
            <tr key={item.id}>
              <td className={styles.tbodyTd}>
                <div className={styles.cellBackground}></div>
                <strong>{item.brand}</strong> - {item.websiteCategory} - ({item.colour}, {item.size}
                )
              </td>
              <td className={styles.tbodyTd}>1</td>
              <td className={styles.tbodyTd}>£{item.choixcePrice * (1 - vat)}</td>
              <td className={styles.tbodyTd}>£{item.choixcePrice * (1 - vat)}</td>
              <td className={styles.tbodyTd}>£{item.choixcePrice * vat}</td>
              <td className={styles.tbodyTd}>£{item.choixcePrice}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className={styles.tableTotal}>
        <tbody>
          <tr>
            <td width="79%"></td>
            <td className={styles.totalTd}>Sub-Total</td>
            <td className={styles.totalTd}>£{subTotal}</td>
          </tr>

          <tr>
            <td></td>
            <td className={styles.totalTd}>VAT (20%)</td>
            <td className={styles.totalTd}>£{vatTotal}</td>
          </tr>

          <tr>
            <td></td>
            <td className={styles.totalTd}>Total</td>
            <td className={styles.totalTd}>£{fullPrice}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
