import React, { useCallback } from 'react';
import { FC, PropsWithChildren } from 'react';
import { useInject } from '../../providers/DIProvider';
import { ApiWrapper } from '../../services/ApiWrapper';
import { useAuth0 } from '@auth0/auth0-react';

export const WithSetHttpErrorCallback: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const api = useInject(ApiWrapper);
  const { logout } = useAuth0();

  useCallback(() => {
    api.addErrorInterceptors({ on401: logout });
  }, [api, logout])();

  return <>{children}</>;
};
