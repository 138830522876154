import { Typography } from 'antd';
import React from 'react';
import { FC } from 'react';

interface Props {
  value?: string;
  placeholder?: string;
}

export const FormatText: FC<Props> = ({ value, placeholder }: Props) => {
  const text = value ?? placeholder;
  return <Typography.Text type={value ? undefined : 'secondary'}>{text}</Typography.Text>;
};
