import { Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

interface Props {
  value?: Date;
  id?: string;
}

export const DATE_IS_NOT_SET_MESSAGE = 'Date is not set';

export const FormatDate: React.FC<Props> = ({ value, id }) => {
  if (!value) {
    return (
      <Typography.Text id={id} type="secondary">
        {DATE_IS_NOT_SET_MESSAGE}
      </Typography.Text>
    );
  }
  return <span id={id}>{dayjs(value).format('LL')}</span>;
};
