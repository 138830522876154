import notification from 'antd/lib/notification';
import {
  TAKE_PAYMENT_ERROR_MESSAGE,
  TAKE_PAYMENT_ERROR_DESCRIPTION,
  TAKE_PAYMENT_SUCCESS_MESSAGE,
  TAKE_PAYMENT_SUCCESS_DESCRIPTION,
} from '../../consts';

export const showTakePaymentErrorMessage = (error?: string): void => {
  /* istanbul ignore next */
  const errorMessage = error ? `: ${error}` : '';
  notification.error({
    message: `${TAKE_PAYMENT_ERROR_MESSAGE}${errorMessage}`,
    description: TAKE_PAYMENT_ERROR_DESCRIPTION,
  });
};

export const showTakePaymentSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: TAKE_PAYMENT_SUCCESS_MESSAGE,
    description: TAKE_PAYMENT_SUCCESS_DESCRIPTION,
  });
};
