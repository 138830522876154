import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import { useInject } from '../../../../providers/DIProvider';
import { OrderStore } from '../../stores';
import {
  showOrderUpdateErrorMessage,
  showOrderUpdateSuccessMessage,
} from '../../../../components/Messages';

export function useSetOrderReadyForPicking(): UseAsyncReturn<void, []> {
  const store = useInject(OrderStore);
  return useAsyncCallback(async () => {
    try {
      await store.setOrderReadyForPicking();
      showOrderUpdateSuccessMessage();
    } catch (e) {
      showOrderUpdateErrorMessage();
    }
  });
}
