import React, { FC } from 'react';
import { CheckOutlined } from '@ant-design/icons/lib/icons';
import { useCompleteOrder } from './useCompleteOrder';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const CompleteOrderButton: FC = () => {
  const { execute, loading } = useCompleteOrder();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to complete this order?"
    >
      <CheckOutlined />
      Complete
    </ConfirmableButton>
  );
};
