import { Table, Typography } from 'antd';
import React, { FC } from 'react';
import { NO_ORDER_ITEMS } from '../../../../consts';
import { OrderItem } from '../../../../dataTypes';
import { getCurrencyString } from '../../../../utils';
import { OrderItemDetails } from './OrderItemDetails/OrderItemDetails';
import styles from './OrderItemList.module.scss';

const { Column } = Table;

interface Props {
  items?: OrderItem[];
  emptyListMessage?: string;
}

export const OrderItemList: FC<Props> = ({ items, emptyListMessage }: Props) => {
  if (!items || items.length === 0) {
    return <Typography.Text type="secondary">{emptyListMessage ?? NO_ORDER_ITEMS}</Typography.Text>;
  }

  return (
    <Table
      dataSource={items}
      rowKey="id"
      className={styles.table}
      pagination={false}
      expandable={{
        // eslint-disable-next-line react/display-name
        expandedRowRender: (record: OrderItem) => <OrderItemDetails item={record} />,
      }}
    >
      <Column title={<span className={styles.title}>Sku</span>} dataIndex="sku" key="sku" />
      <Column
        title={<span className={styles.title}>Category</span>}
        dataIndex="websiteCategory"
        key="websiteCategory"
      />
      <Column title={<span className={styles.title}>Brand</span>} dataIndex="brand" key="brand" />
      <Column
        title={<span className={styles.title}>Name</span>}
        dataIndex="itemName"
        key="itemName"
      />
      <Column
        title={<span className={styles.title}>Colour</span>}
        dataIndex="colour"
        key="colour"
      />
      <Column title={<span className={styles.title}>Size</span>} dataIndex="size" key="size" />
      <Column
        title={<span className={styles.title}>Cost Price</span>}
        dataIndex="costPrice"
        key="costPrice"
        align="right"
        render={(costPrice: number) => <span>{getCurrencyString(costPrice)}</span>}
      />
      <Column
        title={<span className={styles.title}>Choixce Price</span>}
        dataIndex="choixcePrice"
        key="choixcePrice"
        align="right"
        render={(choixcePrice: number) => <span>{getCurrencyString(choixcePrice)}</span>}
      />
    </Table>
  );
};
