import { Button, Popover, Space } from 'antd';
import React, { FC, useState } from 'react';

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  onConfirm: React.MouseEventHandler<HTMLElement>;
  confirmationText?: string;
  confirmButtonText?: string;
  declineButtonText?: string;
}

export const ConfirmableButton: FC<Props> = (props) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const content = (
    <Space align={'end'} wrap={true} direction={'horizontal'} className={'hello'} size={'large'}>
      <Button
        shape="round"
        size={'middle'}
        type={'default'}
        onClick={(e) => {
          props.onConfirm(e);
          setPopoverOpen(false);
        }}
      >
        {props.confirmButtonText ?? 'Yes'}
      </Button>
      <Button shape="round" size={'middle'} type={'primary'} onClick={() => setPopoverOpen(false)}>
        {props.declineButtonText ?? 'No'}
      </Button>
    </Space>
  );

  return (
    <Popover
      visible={isPopoverOpen}
      placement="top"
      title={<h3>{props.confirmationText ?? 'Are you sure?'}</h3>}
      content={content}
      trigger="click"
    >
      <Button
        type="primary"
        shape="round"
        size="large"
        onClick={() => setPopoverOpen(true)}
        disabled={props.disabled}
      >
        {props.children}
      </Button>
    </Popover>
  );
};
