import { FC } from 'react';
import styles from './SubmittedStatusView.module.scss';
import { observer } from 'mobx-react-lite';
import { CancelOrderButton } from '../../components/CancelOrderButton';
import { ArchiveOrderButton } from 'pages/OrderDetails/components/ArchiveOrderButton';
import useConfig from 'hooks/useConfig';
import { Space } from 'antd';
import { ReadyForPickingButton } from 'pages/OrderDetails/components/ReadyForPickingButton';
import { TakeDepositButton } from 'pages/OrderDetails/components/TakeDepositButton';

export const SubmittedStatusView: FC = observer(() => {
  const { config } = useConfig();

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Space>
          {config?.allowArchiveOrders && <ArchiveOrderButton />}
          <CancelOrderButton />
        </Space>
        <Space>
          {config?.allowSetReadyForPicking && <ReadyForPickingButton />}
          {config?.allowTakeDeposit && <TakeDepositButton />}
        </Space>
      </div>
    </>
  );
});
