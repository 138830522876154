import { ChargeRequest, Order, PaymentMethod, PaymentResult, Purchase } from '../dataTypes';
import {
  OrderListItemResponse,
  OrderResponse,
  OrderReturn,
  Page,
  PaymentMethodList,
  PermissionsResponse,
} from './dataTypes';

import {
  makeGetOrderListUrl,
  makeGetOrderUrl,
  makePutOrderDispatchUrl,
  makePostOrderItemsUrl,
  makePostOrderPurchaseUrl,
  makePutOrderUrl,
  makePutOrderCancelUrl,
  makePutOrderReturnUrl,
  makePutCompleteUrl,
  makePutOrderDeliveredUrl,
  makeGetOrderReturnUrl,
  makeGetOrderPaymentMethodsUrl,
  makeGetUserPermissionsUrl,
  makeGetChargeRequestUrl,
  makeUnarchiveOrderUrl,
  makeArchiveOrderUrl,
  makePostTakePaymentUrl,
  makeSetOrderReadyForPickingUrl,
  makeRevertOrderToSubmittedUrl,
  makePatchPaymentMethodUrl,
  makeTakeDepositUrl,
  makeCaptureDepositUrl,
} from './apiUrls';
import { singleton } from 'tsyringe';
import { ApiWrapper, ApiWrapperInstance } from './ApiWrapper';

export const ordersPath = makeGetOrderListUrl();

interface FileUploadsArgs {
  id: string;
  file: File;
}

// TODO: Split this class into multiple around different business contexts
@singleton()
export class ApiService {
  constructor(private readonly apiWrapper: ApiWrapper) {}

  private get api(): ApiWrapperInstance {
    return this.apiWrapper.instance;
  }

  async putOrder(order: Order): Promise<OrderResponse> {
    const orderResponse = await this.api.put<OrderResponse>(makePutOrderUrl(order.id), order);
    return orderResponse.data;
  }

  async getOrderList(
    offset: number,
    size: number,
    statuses: string[],
  ): Promise<Page<OrderListItemResponse>> {
    const response = await this.api.get<Page<OrderListItemResponse>>(ordersPath, {
      params: {
        offset,
        size,
        statuses,
      },
    });

    return response.data;
  }

  async getOrder(id: string): Promise<OrderResponse> {
    const orderResponse = await this.api.get<OrderResponse>(makeGetOrderUrl(id));

    return orderResponse.data;
  }

  async postOrderItemsFile({ id, file }: FileUploadsArgs): Promise<OrderResponse> {
    const formData = new FormData();
    formData.set('File', file);

    return (await this.api.post<OrderResponse>(makePostOrderItemsUrl(id), formData)).data;
  }

  async postPurchase(id: Order['id'], purchase: Purchase): Promise<OrderResponse> {
    const response = await this.api.post<OrderResponse>(makePostOrderPurchaseUrl(id), purchase);
    return response.data;
  }

  async putOrderDispatch(
    id: Order['id'],
    minDiscount: number,
    maxDiscount: number,
  ): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makePutOrderDispatchUrl(id), {
      minDiscount,
      maxDiscount,
    });
    return response.data;
  }

  async archiveOrder(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makeArchiveOrderUrl(id));
    return response.data;
  }

  async unarchiveOrder(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makeUnarchiveOrderUrl(id));
    return response.data;
  }

  async setOrderReadyForPicking(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makeSetOrderReadyForPickingUrl(id));
    return response.data;
  }

  async revertOrderToSubmitted(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makeRevertOrderToSubmittedUrl(id));
    return response.data;
  }

  async putOrderCancel(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makePutOrderCancelUrl(id));
    return response.data;
  }

  async putOrderReturn(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makePutOrderReturnUrl(id));
    return response.data;
  }

  async getOrderReturn(id: Order['id']): Promise<OrderReturn> {
    const response = await this.api.get<OrderReturn>(makeGetOrderReturnUrl(id));
    return response.data;
  }

  async getOrderPaymentMethods(id: Order['id']): Promise<PaymentMethodList> {
    const response = await this.api.get<PaymentMethodList>(makeGetOrderPaymentMethodsUrl(id));
    return response.data;
  }

  async patchPaymentMethod(
    id: string,
    paymentMethod: Partial<PaymentMethod>,
  ): Promise<PaymentMethod> {
    const response = await this.api.patch<PaymentMethod>(
      makePatchPaymentMethodUrl(id),
      paymentMethod,
    );
    return response.data;
  }

  async putCompleteOrder(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makePutCompleteUrl(id));
    return response.data;
  }

  async putOrderDelivered(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.put<OrderResponse>(makePutOrderDeliveredUrl(id));
    return response.data;
  }

  async getUserPermissions(): Promise<string[]> {
    const response = await this.api.get<PermissionsResponse>(makeGetUserPermissionsUrl());
    return response.data.permissions;
  }

  async getChargeRequest(id: Order['id']): Promise<ChargeRequest> {
    const response = await this.api.get<ChargeRequest>(makeGetChargeRequestUrl(id));
    return response.data;
  }

  async postTakePayment(
    id: Order['id'],
    paymentMethodId: string,
    customerId: string,
  ): Promise<PaymentResult> {
    const response = await this.api.post<PaymentResult>(makePostTakePaymentUrl(id), {
      paymentMethodId,
      customerId,
    });
    return response.data;
  }

  async takeDeposit(
    id: Order['id'],
    paymentMethodId: string,
    customerId: string,
  ): Promise<PaymentResult> {
    const response = await this.api.put<PaymentResult>(makeTakeDepositUrl(id), {
      paymentMethodId,
      customerId,
    });
    return response.data;
  }

  async captureDeposit(id: Order['id']): Promise<void> {
    await this.api.put<PaymentResult>(makeCaptureDepositUrl(id));
  }
}
