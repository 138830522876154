import React, { FC } from 'react';
import styles from './ReadyForPickingStatusView.module.scss';
import { observer } from 'mobx-react-lite';
import {
  showFileUploadErrorMessage,
  showFileUploadSuccessMessage,
  showFileUploadErrorWithCustomMessage,
} from '../../../../components/Messages';
import { useOrderStore } from '../../hooks/useOrderStore';
import { UploadComponent } from '../../../../components/UploadComponent';
import { OrderItemList } from '../../components/OrderItemList';
import { PrintButton } from '../../components/PrintButton/PrintButton';
import { useOrderPaymentMethods } from '../../serverCache/useOrderPaymentMethods';
import useConfig from 'hooks/useConfig';
import { OrderDeliveryWidget } from './components/OrderDeliveryWidget';
import { OrderReceiptButton } from './components/OrderReceiptButton';
import { RevertToSubmittedButton } from 'pages/OrderDetails/components/RevertToSubmittedButton';
import { OrderDispatchWidget } from './components/OrderDispatchWidget';

export const ReadyForPickingStatusView: FC = observer(() => {
  const { currentOrder, uploadItemsFile } = useOrderStore();
  const { config } = useConfig();

  const onUpload = async (file: File): Promise<void> => {
    return uploadItemsFile(currentOrder.id, file, {
      onErrorWithMessage: (_, message: string) => showFileUploadErrorWithCustomMessage(message),
      onSuccess: showFileUploadSuccessMessage,
      onError: showFileUploadErrorMessage,
    });
  };

  const { data: paymentMethodList } = useOrderPaymentMethods(currentOrder.id);
  const confirmationRequired =
    paymentMethodList === undefined || paymentMethodList.paymentMethods.length === 0;

  return (
    <>
      {config?.allowRevertToSubmitted && (
        <div className={styles.buttonsContainer}>
          <RevertToSubmittedButton />
        </div>
      )}
      <OrderDeliveryWidget>
        <PrintButton
          confirmationRequired={confirmationRequired}
          confirmationText="This customer does not have credit card verified. Are you sure you want to print label and send parcel without it?"
        />
        <OrderReceiptButton orderId={currentOrder.id} />
      </OrderDeliveryWidget>
      <OrderDispatchWidget itemsCount={currentOrder.items?.length} />
      <OrderItemList items={currentOrder.items} />
      <UploadComponent onChange={onUpload} />
    </>
  );
});
