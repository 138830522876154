import React, { ReactNode, FC } from 'react';
import classNames from 'classnames';
import styles from './TagComponent.module.scss';

export enum TagType {
  small,
  middle,
}

interface TagProps {
  children: ReactNode;
  type?: TagType;
  className?: string;
}

export const TagComponent: FC<TagProps> = ({ children, type, className }: TagProps) => (
  <div
    className={classNames(className, styles.tag, {
      [styles.tag__small]: type === TagType.small,
    })}
  >
    {children}
  </div>
);
