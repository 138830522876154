import React from 'react';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { FormValue } from '../../../../components/form/FormValue';
import { Totals } from '../../../../../../dataTypes';
import { getCurrencyString, getPercentageString } from '../../../../../../utils';

const formProps: FormProps = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
  hideRequiredMark: true,
  labelAlign: 'left',
  size: 'small',
};

interface Props {
  totals: Totals;
}

type StringTotals = Omit<Record<keyof Totals, string>, 'numberOfPurchasedItems'>;

function formatTotals(totals: Totals): StringTotals {
  return {
    costPrice: getCurrencyString(totals.costPrice),
    sellPrice: getCurrencyString(totals.sellPrice),
    margin: getPercentageString(totals.margin),
    stylistCommission: getCurrencyString(totals.stylistCommission),
    profit: getCurrencyString(totals.profit),
  };
}

export const PurchaseTotals: React.FC<Props> = ({ totals }) => (
  <Form {...formProps} initialValues={formatTotals(totals)}>
    <Form.Item label="Sell price" name={['sellPrice']}>
      <FormValue />
    </Form.Item>
    <Form.Item label="Stylist commission" name={['stylistCommission']}>
      <FormValue />
    </Form.Item>
  </Form>
);
