import { notification } from 'antd';
import { FILE_UPLOAD_SUCCESS_MESSAGE, FILE_UPLOAD_SUCCESS_DESCRIPTION } from '../../consts';

export const showFileUploadSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: FILE_UPLOAD_SUCCESS_MESSAGE,
    description: FILE_UPLOAD_SUCCESS_DESCRIPTION,
  });
};
