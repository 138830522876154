import { FC, useState } from 'react';
import { Card, Input } from 'antd';
import styles from './OrderDispatchWidget.module.scss';
import { useInject } from '../../../../../../providers/DIProvider';
import { ShippingStore } from '../../../../stores/ShippingStore';
import { DispatchOrderButton } from '../../../../components/DispatchOrderButton/DispatchOrderButton';
import { observer } from 'mobx-react-lite';

export const TITLE = 'What discount are they allowed have?';

interface Props {
  itemsCount?: number;
}

export const OrderDispatchWidget: FC<Props> = observer(({ itemsCount }: Props) => {
  const shippingStore = useInject(ShippingStore);

  const [maxDiscount, setMaxDiscount] = useState(70);

  if (!shippingStore.isReady || !shippingStore.isShipmentBooked) {
    return null;
  }

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.cardTitle}>{TITLE}</div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWrapperSpan}>
            Max discount for <span>{itemsCount} items:</span>
          </div>
          <Input
            addonBefore="%"
            name="discount"
            type="number"
            min={0}
            max={100}
            size={'large'}
            value={maxDiscount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMaxDiscount(Number(e.target.value));
            }}
          />
          <div className={styles.inputPercentage}>%</div>
        </div>
      </Card>
      <div className={styles.buttonContainer}>
        <DispatchOrderButton maxDiscount={maxDiscount / 100} />
      </div>
    </>
  );
});
