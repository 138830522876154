export const NO_COMMENTS_PROVIDED = 'No comments provided';
export const NO_ORDER_ITEMS = 'Items for this order have not been uploaded yet';
export const NOT_SET = 'Not set';

export const FILE_UPLOAD_SUCCESS_MESSAGE = 'Success';
export const FILE_UPLOAD_SUCCESS_DESCRIPTION = 'File has been successfully uploaded';
export const FILE_UPLOAD_ERROR_MESSAGE = 'Error';
export const FILE_UPLOAD_ERROR_DESCRIPTION =
  'An error has occured while uploading the file, please verify contents of your file or try again later';

export const ORDER_UPDATE_SUCCESS_MESSAGE = 'Success';
export const ORDER_UPDATE_SUCCESS_DESCRIPTION = 'Order has been successfully updated';
export const ORDER_UPDATE_ERROR_MESSAGE = 'Error';
export const ORDER_UPDATE_ERROR_DESCRIPTION =
  'An error has occured while updating the order, please try again later';

export const PURCHASE_SUCCESS_MESSAGE = 'Success';
export const PURCHASE_SUCCESS_DESCRIPTION =
  'Selected items and sell price have been successfully submitted for purchase';
export const PURCHASE_ERROR_MESSAGE = 'Error';
export const PURCHASE_ERROR_DESCRIPTION =
  'An error has occured while submitting, please try again later';

export const NO_ITEMS_SELECTED = 'At least one item must be selected';
export const INCORRECT_SELL_PRICE = 'Sell price must be greater then or equal to min price: £';

export const RETURN_ERROR_MESSAGE = 'Error';
export const RETURN_ERROR_DESCRIPTION = 'An error has occured while return, please try again later';

export const TAKE_PAYMENT_ERROR_MESSAGE = 'Error';
export const TAKE_PAYMENT_ERROR_DESCRIPTION =
  'An error has occured while making a payment. Please try again or contact IT if problem persists.';

export const TAKE_PAYMENT_SUCCESS_MESSAGE = 'Success';
export const TAKE_PAYMENT_SUCCESS_DESCRIPTION = 'Payment has been made successfully.';

export const CAPTURE_DEPOSIT_ERROR_MESSAGE = 'Error';
export const CAPTURE_DEPOSIT_ERROR_DESCRIPTION =
  'An error has occured while capturing a deposit. Please try again or contact IT if problem persists.';

export const CAPTURE_DEPOSIT_SUCCESS_MESSAGE = 'Success';
export const CAPTURE_DEPOSIT_SUCCESS_DESCRIPTION = 'Deposit has been captured successfully.';

export const CHARGE_REQUEST_SUCCESS_MESSAGE = 'Success';
export const CHARGE_REQUEST_SUCCESS_DESCRIPTION = 'Charge request has been successfully submitted';
export const CHARGE_REQUEST_ERROR_MESSAGE = 'Error';
export const CHARGE_REQUEST_ERROR_DESCRIPTION =
  'An error has occured while requesting a charge, please try again later';

export const PERFORM_CHARGE_SUCCESS_MESSAGE = 'Success';
export const PERFORM_CHARGE_SUCCESS_DESCRIPTION = 'Customer has been charged successfully';
export const PERFORM_CHARGE_ERROR_MESSAGE = 'Error';
export const PERFORM_CHARGE_ERROR_DESCRIPTION =
  'An error has occured while attempting to charge customer. This is likely due to insufficient funds. Please try again later or submit a new charge request with lower amount.';

export const UPDATE_CARD_SUCCESS_MESSAGE = 'Success';
export const UPDATE_CARD_SUCCESS_DESCRIPTION =
  'Customer billing address and cardholder name were updated.';
export const UPDATE_CARD_ERROR_MESSAGE = 'Error';
export const UPDATE_CARD_DESCRIPTION =
  'An error has occured while attempting to update card details. Please contact IT guys.';
