import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import {
  showOrderUpdateErrorMessage,
  showOrderUpdateSuccessMessage,
} from '../../../../components/Messages';
import { useOrderStore } from '../../hooks/useOrderStore';

/* istanbul ignore file */

export function useRevertOrderToDelivered(): UseAsyncReturn<void, []> {
  const store = useOrderStore();
  return useAsyncCallback(async () => {
    try {
      await store.revertOrderToDelivered();
      showOrderUpdateSuccessMessage();
    } catch (e) {
      showOrderUpdateErrorMessage();
    }
  });
}
