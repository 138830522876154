import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useOrderStore } from '../../hooks/useOrderStore';
import { OrderItemList } from '../../components/OrderItemList';

export const CancelledStatusView: FC = observer(() => {
  const { currentOrder } = useOrderStore();

  return <OrderItemList items={currentOrder.items} />;
});
