import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import styles from './OrderReceipt.module.scss';
import ChoixceBlack from '../../images/ChoixceBlack.svg';
import { useOrderDetails } from './serverCache/useOrderDetails';
import classNames from 'classnames';
import { InitLoadingComponent } from '../../components/InitLoadingComponent';
// import logoMiniIcon from '../../images/logo-mini.svg';
// import logoIcon from '../../images/ChoixceBlack.svg';
// import phoneIcon from '../../images/phone.svg';
// import mailIcon from '../../images/email.svg';
// import addressIcon from '../../images/address.svg';
import statusImage from '../../images/status_new.png';
import returnImage from '../../images/QR_white.png';
import { getCurrencyString } from '../../utils';

/* istanbul ignore file */

interface RouteParams {
  id: string; // orderId
}
const publicWebAddress = process.env.REACT_APP_PUBLIC_WEB_ADDRESS ?? 'https://choixce.com';

export const OrderReceipt: FC = () => {
  const { id: orderId } = useParams<RouteParams>();
  const { data: order, isLoading } = useOrderDetails(orderId);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const orderItems = order?.items ?? [];

  if (isLoading) {
    return <InitLoadingComponent />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src={ChoixceBlack} alt="logo" />
        </div>
        <div className={styles.code}>
          <QRCode value={`${publicWebAddress}/my/orders/${orderId}`} quietZone={0} />
          <h2 className={styles.readableCode}>
            <strong>Order ID</strong> {orderId}
          </h2>
        </div>
      </div>
      <div className={styles.titleBox}>
        <h1 className={styles.title}>
          hey {order?.stylingProfile.firstName.trim()} {order?.stylingProfile.lastName.trim()}!
        </h1>
        <h2 className={styles.subtitle}>
          Thank you for choosing us! All items have been carefully hand selected by your stylist and
          are based on your individual requirements. Enjoy!
        </h2>
      </div>
      <table className={classNames(styles.table, styles.marginBottom50)}>
        <thead className={styles.thead}>
          <tr>
            <td>item name</td>
            <td>rrp</td>
            <td>
              <span className={styles.textRed}>YOU PAY</span>
            </td>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {orderItems.map((item) => (
            <tr key={item.id}>
              <td>
                <div className={styles.cellBackground}></div>
                <strong>{item.brand}</strong> - {item.websiteCategory} - ({item.colour}, {item.size}
                )
              </td>
              <td>{getCurrencyString(item.choixcePrice)}</td>
              <td>
                <span
                  className={classNames({
                    [styles.textRed]: true,
                  })}
                ></span>
              </td>
            </tr>
          ))}
          <tr className={styles.tfoot}>
            <td>
              <h2 className={styles.total}>Total</h2>
            </td>
            <td>
              <span></span>
            </td>
            <td>
              <span
                className={classNames({
                  [styles.textRed]: true,
                })}
              ></span>
            </td>
          </tr>
          <tr className={styles.thead}>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className={styles.secondPage}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={ChoixceBlack} alt="logo" />
          </div>
          <div className={styles.code}>
            <QRCode value={`${publicWebAddress}/my/orders/${orderId}`} quietZone={0} />
            <h2 className={styles.readableCode}>
              <strong>Order ID</strong> {orderId}
            </h2>
          </div>
        </div>
        {/* <div className={styles.contacts}>
          <div className={styles.contacts__imgBox}>
            <img src={logoMiniIcon} alt="" className={styles.contacts__imgBox__circle} />
            <img src={logoIcon} alt="" className={styles.contacts__imgBox__title} />
          </div>
          <div className={styles.contacts__box}>
            <div className={styles.contacts__box__title}>Leigh Sikora</div>
            <div className={styles.contacts__box__contact}>
              <img src={addressIcon} alt="address" /> Choixce Limited, 11 Cronin Courtyard, Weldon
              South Industrial Estate, Corby, NN18 8AG
            </div>
            <div className={styles.contacts__box__contact}>
              <img src={mailIcon} alt="mail" /> leigh@choixce.com
            </div>
            <div className={styles.contacts__box__contact}>
              <img src={phoneIcon} alt="phone" /> <span>07706 364209</span>
            </div>
          </div>
        </div> */}
        <img
          src={statusImage}
          alt="status"
          className={classNames(styles.status, styles.marginBottom50)}
        />
        <div className={classNames(styles.titleBox, styles.marginBottom400)}>
          <h1 className={styles.title}>whats next?</h1>
          <ul className={styles.list}>
            <li className={styles.n1}>
              You have seven days to try on the items in the comfort of your own home. Don’t forget
              to mix and match with what’s already in your wardrobe
            </li>
            <li className={styles.n2}>
              To make a payment log into your account and go to My Orders
              (https://choixce.com/my/orders)
            </li>
            <li className={styles.n3}>
              Select what you want to keep and what you want to return for free
            </li>
            <li className={styles.n4}>
              Complete four orders and become a Pay-What-You-Want Gold member!
            </li>
          </ul>
        </div>
        <h1 className={styles.title}>How to return your items:</h1>
        <h2 className={styles.subtitle}>
          Scan the below QR code to return your items – no printer required.
        </h2>
        <img
          src={returnImage}
          alt="https://www.royalmail.com/track-my-return/create/5726"
          className={classNames(styles.status, styles.marginBottom50)}
        />
        <h1 className={styles.title}>How to reorder and pay what you want:</h1>
        <h2 className={classNames(styles.subtitle, styles.marginBottom50)}>
          <p>
            To gain access to our pay what you want sliding scale, all you have to do is complete 4
            boxes with us.
          </p>
          <p>
            To reorder another box, log into your account and select another box - please note we
            can only process one order at a time, per household.
          </p>
          <p>
            On completion of your 4th box, the sliding scale is unlocked in full to allow you to
            select the price you want to pay – It’s your Choixce!
          </p>
        </h2>
      </div>
    </div>
  );
};
