/* istanbul ignore file */
import React, { ReactNode } from 'react';

interface Props {
  message: ReactNode;
  children?: ReactNode;
}

interface State {
  error?: Error;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error): Partial<State> {
    return {
      error,
    };
  }

  render(): ReactNode {
    if (this.state.error) {
      return this.props.message;
    }

    return this.props.children;
  }
}
