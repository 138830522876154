import { DetailsItemBlock } from '../../components/DetailsItemBlock';
import { PageLayout } from '../../components/PageLayout';
import { FC } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { FormProps } from 'antd/lib/form';
import {
  PaymentMethodFormFields as Fields,
  paymentMethodFormConfig as cfg,
} from './paymentMethodFormConfig';
import { OrderStore } from '../../stores';
import { useGetGoToDetailsCallback } from '../../hooks/useGetGoToDetailsCallback';
import { useInject } from '../../../../providers/DIProvider';
import { ButtonWrapper } from '../../../../components/ButtonWrapper/ButtonWrapper';
import { GoBackButton } from '../../components/GoBackButton';
import { useOnPaymentMethodSubmit } from './useOnPaymentMethodSubmit';
import { useOrderPaymentMethods } from 'pages/OrderDetails/serverCache/useOrderPaymentMethods';

import styles from './PaymentMethodForm.module.scss';

export const formProps: FormProps = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
  hideRequiredMark: true,
  labelAlign: 'left',
  size: 'small',
};

export const PaymentMethodForm: FC = () => {
  const [form] = Form.useForm();

  const { currentOrder } = useInject(OrderStore);
  const { stylingProfile } = currentOrder;

  const { data: paymentMethodList } = useOrderPaymentMethods(currentOrder.id);

  const defaultPaymentMethod =
    paymentMethodList?.paymentMethods.find((pm) => pm.isDefault) ||
    paymentMethodList?.paymentMethods[0];

  console.log(defaultPaymentMethod);

  const goToDetailsPage = useGetGoToDetailsCallback();

  const { execute } = useOnPaymentMethodSubmit(defaultPaymentMethod?.id || '');

  const title = `${currentOrder.id} - ${stylingProfile.firstName} ${stylingProfile.lastName}`;

  if (!defaultPaymentMethod) {
    return <div>Customer has no cards added.</div>;
  }

  const fillFromOrder = (): void => {
    form.setFieldsValue({
      billingAddress: {
        firstName: currentOrder.customerProfile.firstName,
        lastName: currentOrder.customerProfile.lastName,
        phone: currentOrder.address.phone,
        house: currentOrder.address.house,
        street: currentOrder.address.street,
        city: currentOrder.address.city,
        county: currentOrder.address.county,
        postcode: currentOrder.address.postcode,
      },
      card: {
        cardholderName: `${currentOrder.customerProfile.firstName} ${currentOrder.customerProfile.lastName}`,
      },
    });
  };

  return (
    <PageLayout
      title={title}
      status={currentOrder.status}
      buttons={<GoBackButton />}
      salesforceLink={currentOrder.salesforceOpportunityLink}
      hideStatusManager={true}
    >
      <Form form={form} {...formProps} initialValues={defaultPaymentMethod} onFinish={execute}>
        <div className={styles.form}>
          <DetailsItemBlock title={'Billing Address'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.ADDRESS_FIRSTNAME]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_LASTNAME]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_PHONE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_HOUSE]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_STREET]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_CITY]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_COUNTY]}>
              <Input />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_POSTCODE]}>
              <Input />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Card'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.CARDHOLDER_NAME]}>
              <Input />
            </Form.Item>
          </DetailsItemBlock>
        </div>
        <ButtonWrapper>
          <Space className={styles.buttonWrapper}>
            <Button onClick={fillFromOrder} type="dashed" shape="round" size="large">
              Fill from delivery address
            </Button>
            <Button onClick={goToDetailsPage} type="default" shape="round" size="large">
              Cancel
            </Button>
            <Button type="primary" shape="round" size="large" htmlType="submit">
              Save
            </Button>
          </Space>
        </ButtonWrapper>
      </Form>
    </PageLayout>
  );
};
