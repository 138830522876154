import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './OrderStatusBadge.module.scss';
import { OrderStatus } from '../../dataTypes';

interface Props {
  status: OrderStatus;
}

export const OrderStatusBadge: FC<Props> = ({ status }) => {
  return (
    <div
      className={classNames({
        [styles.state]: true,
        [styles.stateSubmitted]: status === OrderStatus.Submitted,
        [styles.stateReadyForPicking]: status === OrderStatus.ReadyForPicking,
        [styles.stateDispatched]: status === OrderStatus.Dispatched,
        [styles.stateDelivered]: status === OrderStatus.Delivered,
        [styles.statePendingPayment]: status === OrderStatus.PendingPayment,
        [styles.statePaymentOverdue]: status === OrderStatus.PaymentOverdue,
        [styles.statePendingReturn]: status === OrderStatus.PendingReturn,
        [styles.stateReturnOverdue]: status === OrderStatus.ReturnOverdue,
        [styles.stateCompleted]: status === OrderStatus.Completed,
        [styles.stateCancelled]: status === OrderStatus.Cancelled,
        [styles.stateArchived]: status === OrderStatus.Archived,
        [styles.stateDeliveryOverdue]: status === OrderStatus.DeliveryOverdue,
        [styles.stateFullyReturned]: status === OrderStatus.FullyReturned,
      })}
      title={status}
    >
      {status}
    </div>
  );
};
