import React, { FC, useCallback, useState } from 'react';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './UploadComponent.module.scss';

export interface UploadComponentProps {
  onChange(file: File): void | Promise<unknown>;
}

export const UPLOAD_IS_IN_PROGRESS_TEXT = 'File uploading is in progress';
export const UPLOAD_AREA_TEXT = 'Click or drag file to this area to upload';

export const UploadComponent: FC<UploadComponentProps> = ({ onChange }) => {
  const [isLoading, setLoading] = useState(false);

  const onChangeWrapper = useCallback(
    async (file: File) => {
      try {
        setLoading(true);
        await onChange(file);
      } finally {
        setLoading(false);
      }
    },
    [onChange],
  );

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files[0]) {
        onChangeWrapper(event.dataTransfer.files[0]);
      }
    },
    [onChangeWrapper],
  );

  const onChangeInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        await onChangeWrapper(file);
        target.value = ''; // clear input value
      }
    },
    [onChangeWrapper],
  );

  const onDragOver = useCallback((event: React.DragEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  return (
    <label
      className={styles.upload}
      data-testid={'droppable'}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {isLoading ? (
        <>
          <LoadingOutlined className={styles.icon} />
          <p>{UPLOAD_IS_IN_PROGRESS_TEXT}</p>
        </>
      ) : (
        <>
          <InboxOutlined className={styles.icon} size={50} />
          <p>{UPLOAD_AREA_TEXT}</p>
        </>
      )}
      <input className={styles.input} accept={'.csv'} onChange={onChangeInput} type="file" />
    </label>
  );
};
