import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import { useOrderPaymentMethods } from 'pages/OrderDetails/serverCache/useOrderPaymentMethods';
import { useOrderStore } from 'pages/OrderDetails/hooks/useOrderStore';
import {
  showTakePaymentErrorMessage,
  showTakePaymentSuccessMessage,
} from 'components/Messages/showTakePaymentMessages';
import { PaymentResultStatus } from 'dataTypes';

export function useTakePayment(): UseAsyncReturn<void, []> {
  const store = useOrderStore();

  const { data: paymentMethodList } = useOrderPaymentMethods(store.currentOrder.id);

  const paymentMethodId =
    paymentMethodList?.paymentMethods.find((pm) => pm.isDefault)?.id ||
    paymentMethodList?.paymentMethods[0]?.id ||
    '';

  return useAsyncCallback(async () => {
    try {
      const result = await store.takePayment(paymentMethodId);
      result.status === PaymentResultStatus.Failed
        ? showTakePaymentErrorMessage(result.errorMessage)
        : showTakePaymentSuccessMessage();
    } catch (e) {
      showTakePaymentErrorMessage();
    }
  });
}
