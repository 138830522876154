import React, { FC, useEffect, useState } from 'react';
import { Card, Input, Slider } from 'antd';
import { CalculatedPrices } from '../../../../stores';
import classNames from 'classnames';
import {
  eventLens,
  simpleLens,
  useCallbackWithLens,
} from '../../../../../../hooks/useCallbackWithLens/useCallbackWithLens';
import styles from './PriceSlider.module.scss';
import { PriceInfo } from '../PriceInfo';
import { SliderMark, SliderMarkTypes } from '../SliderMark';
import { getShortCurrencyString } from '../../../../../../utils';

interface Props {
  sellPrice: number;

  onChangeSellPrice(newPrice: number): void;

  onBlurSellPriceInput(): void;

  calculatedPrices: CalculatedPrices;

  itemsCount: number;
}

export const TITLE = 'What would they like to pay?';

export const PriceSlider: FC<Props> = ({
  calculatedPrices: {
    discountToCustomer,
    fullPrice,
    minPrice,
    savingsToCustomer,
    stylistCommissionAmount,
    stylistCommissionThreshold,
  },
  onChangeSellPrice,
  onBlurSellPriceInput,
  sellPrice,
  itemsCount,
}: Props) => {
  const [price, setPrice] = useState(sellPrice);

  useEffect(() => {
    setPrice(sellPrice);
  }, [price, sellPrice]);

  const onInputChange = useCallbackWithLens(onChangeSellPrice, eventLens);
  const onValueChange = useCallbackWithLens(onChangeSellPrice, simpleLens);
  return (
    <Card className={styles.host}>
      <div className={styles.hostTitle}>{TITLE}</div>
      <div className={styles.inputWrapper}>
        <div className={styles.inputWrapperSpan}>
          Sell price for <span>{itemsCount} items:</span>
        </div>
        <Input
          addonBefore="£"
          name="customer-price"
          type="number"
          size={'large'}
          value={price}
          onChange={onInputChange}
          onBlur={onBlurSellPriceInput}
        />
        <div className={styles.inputCurrency}>GBP</div>
      </div>
      <Slider
        className={classNames({
          [styles.silver]: sellPrice >= stylistCommissionThreshold && discountToCustomer > 60,
          [styles.black]: discountToCustomer <= 60 && discountToCustomer >= 30,
          [styles.gold]: discountToCustomer <= 30,
        })}
        value={price}
        tipFormatter={(value) => (
          <div>
            <PriceInfo sellPrice={sellPrice} discount={discountToCustomer} />
          </div>
        )}
        marks={{
          [stylistCommissionThreshold]: (
            <SliderMark
              type={SliderMarkTypes.Grey}
              value={getShortCurrencyString(stylistCommissionThreshold)}
              title={'Commission threshold'}
            />
          ),
          [fullPrice]: (
            <SliderMark
              type={SliderMarkTypes.Green}
              value={getShortCurrencyString(fullPrice)}
              title={'Full price'}
            />
          ),
          [minPrice]: (
            <SliderMark
              type={SliderMarkTypes.Red}
              value={getShortCurrencyString(minPrice)}
              title={'Minimal payment'}
            />
          ),
        }}
        onChange={onValueChange}
        min={minPrice}
        max={fullPrice}
      />
      <div className={styles.sliderInfo}>
        <div className={styles.sliderInfoBlock}>
          <div className={styles.sliderInfoLine}>
            <span>Min Price</span>
            <strong>{getShortCurrencyString(minPrice)}</strong>
          </div>
          <div className={styles.sliderInfoLine}>
            <span>Full Price</span>
            <strong>{getShortCurrencyString(fullPrice)}</strong>
          </div>
          <div className={styles.sliderInfoLine}>
            <span>Commision Threshold</span>
            <strong>{getShortCurrencyString(stylistCommissionThreshold)}</strong>
          </div>
        </div>
        <div className={styles.sliderInfoBlock}>
          <div className={styles.sliderInfoLine}>
            <span>Discount</span>
            <strong>{discountToCustomer}% Off</strong>
          </div>
          <div className={styles.sliderInfoLine}>
            <span>Savings</span>
            <strong>{getShortCurrencyString(savingsToCustomer)}</strong>
          </div>
          <div className={styles.sliderInfoLine}>
            <span>Commission</span>
            <strong>{getShortCurrencyString(stylistCommissionAmount)}</strong>
          </div>
        </div>
      </div>
    </Card>
  );
};
