import React from 'react';
import { Button, Space } from 'antd';
import { FC } from 'react';
import { useGetGoBackCallback } from '../hooks/useGetGoBackCallback';

export const GoBackButton: FC = () => {
  const onGoBack = useGetGoBackCallback();

  return (
    <Space>
      <Button onClick={onGoBack} type="default" shape="round" size="large">
        Back
      </Button>
    </Space>
  );
};
