import React, { FC } from 'react';

import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { LoadingStatuses, UrlParams } from '../../dataTypes';
import { OrderStore } from './stores';
import { OrderView } from './pages/OrderView';
import { OrderEditForm } from './pages/OrderEditForm';
import { Skeleton } from 'antd';
import { useLoadOrder } from './hooks/useLoadOrder';
import { useInject } from '../../providers/DIProvider';
import { Helmet } from 'react-helmet-async';
import { PaymentMethodForm } from './pages/EditCardDetails/PaymentMethodForm';

export const OrderDetails: FC = observer(() => {
  const orderStore = useInject(OrderStore);

  useLoadOrder(orderStore);
  const state = useParams<UrlParams>().state;
  const { loadingStatus, currentOrder } = orderStore;

  console.log(loadingStatus);

  if (loadingStatus !== LoadingStatuses.Finished) {
    return <Skeleton active />;
  }
  const title = `${currentOrder.id} - ${currentOrder.stylingProfile.firstName} ${currentOrder.stylingProfile.lastName}`;

  return (
    <>
      <Helmet>
        <title>Choixce Admin - {title}</title>
      </Helmet>
      {state === 'edit' && <OrderEditForm />}
      {state === 'payment-method' && <PaymentMethodForm />}
      {state !== 'payment-method' && state !== 'edit' && <OrderView />}
    </>
  );
});
