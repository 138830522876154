import notification from 'antd/lib/notification';
import { FILE_UPLOAD_ERROR_MESSAGE } from '../../consts';

export const showFileUploadErrorWithCustomMessage = (message: string): void => {
  /* istanbul ignore next */
  notification.error({
    message: FILE_UPLOAD_ERROR_MESSAGE,
    description: message,
    duration: 0,
  });
};
