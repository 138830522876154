import React from 'react';
import { FC, PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsync } from 'react-use';
import { useInject } from '../../providers/DIProvider';
import { ApiWrapper } from '../../services/ApiWrapper';

export const WithSetAddAccessAndIdKeyToEachRequest: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const api = useInject(ApiWrapper);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  const { loading } = useAsync(() =>
    api.onSetTokenAsync(getAccessTokenSilently(), getIdTokenClaims()),
  );

  if (loading) {
    return null;
  }

  return <>{children}</>;
};
