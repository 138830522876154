import notification from 'antd/lib/notification';
import { FILE_UPLOAD_ERROR_MESSAGE, FILE_UPLOAD_ERROR_DESCRIPTION } from '../../consts';

export const showFileUploadErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: FILE_UPLOAD_ERROR_MESSAGE,
    description: FILE_UPLOAD_ERROR_DESCRIPTION,
    duration: 0,
  });
};
