import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { UnarchiveOrderButton } from 'pages/OrderDetails/components/UnarchiveOrderButton';
import { Space } from 'antd';
import styles from './ArchivedStatusView.module.scss';
import useConfig from 'hooks/useConfig';

export const ArchivedStatusView: FC = observer(() => {
  const { config } = useConfig();

  return (
    <>
      {config?.allowUnarchiveOrders && (
        <div className={styles.buttonsContainer}>
          <Space>
            <UnarchiveOrderButton />
          </Space>
        </div>
      )}
    </>
  );
});
