import { FC } from 'react';
import { PoundOutlined } from '@ant-design/icons/lib/icons';
import { useTakeDeposit } from './useTakeDeposit';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const TakeDepositButton: FC = () => {
  const { execute, loading } = useTakeDeposit();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to charge customer`s card?"
    >
      <PoundOutlined />
      Take Deposit
    </ConfirmableButton>
  );
};
