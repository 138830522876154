/* istanbul ignore file */
import { container, inject, singleton } from 'tsyringe';
import axios, { AxiosInstance } from 'axios';
import { HttpError } from '../dataTypes';
import { IdToken } from '@auth0/auth0-spa-js';

export const ACCESS_DENIED_STATUS_CODE = 401;
export const PERMISSION_DENIED_STATUS_CODE = 403;
export const baseURL = process.env.REACT_APP_API_PATH;

export type ApiWrapperInstance = AxiosInstance;

container.register('AxiosInstance', {
  useValue: axios.create({
    baseURL,
  }),
});

interface ErrorInterceptorsArgs {
  on401?(): void;

  on403?(): void;
}

@singleton()
export class ApiWrapper {
  constructor(@inject('AxiosInstance') public readonly instance: ApiWrapperInstance) {}

  public async onSetTokenAsync(
    callbackAccessToken: Promise<string>,
    callbackIdToken: Promise<IdToken>,
  ): Promise<void> {
    const headers = this.instance.defaults.headers as { [key: string]: string };
    headers['IYC-Auth'] = `Bearer ${await callbackAccessToken}`;
    headers['IYC-AuthId'] = `token ${(await callbackIdToken).__raw}`;
  }

  public addErrorInterceptors({ on401, on403 }: ErrorInterceptorsArgs): number {
    return this.instance.interceptors.response.use(undefined, (error?: HttpError) => {
      if (error?.response?.status === PERMISSION_DENIED_STATUS_CODE && on403) {
        on403();
      }
      if (error?.response?.status === ACCESS_DENIED_STATUS_CODE && on401) {
        /* istanbul ignore next */
        on401();
      }

      return Promise.reject(error);
    });
  }
}
