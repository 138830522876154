import { useQuery, UseQueryResult } from 'react-query';
import { useInject } from 'providers/DIProvider';
import { ApiService } from 'services';

export function usePermissions(): UseQueryResult<string[]> {
  const apiService = useInject(ApiService);

  return useQuery(['permissions'], async () => {
    return await apiService.getUserPermissions();
  });
}
