import { RuleRender } from 'antd/lib/form';
import { QuestionnaireItem, SystemOfMeasure } from '../../../../dataTypes';

export function generateWeightRule(systemOfMeasure: SystemOfMeasure): RuleRender {
  return () => ({
    validator(_, itemValue: QuestionnaireItem) {
      const value = itemValue.answers[0];
      return validateWeightFormat(value, systemOfMeasure);
    },
  });
}

export function validateWeightFormat(
  value: string | undefined,
  systemOfMeasure: SystemOfMeasure,
): Promise<void> {
  if (!value) {
    return Promise.resolve();
  }

  if (
    systemOfMeasure === SystemOfMeasure.Imperial &&
    //e.g. 8 st, 6 lb
    !/^(\d) st(, (\d|(10)|(11)|(12)|(13)) lb)?$/.test(value)
  ) {
    return Promise.reject(new Error('Please enter correct value, e.g. 8 st, 6 lb'));
  }

  if (
    systemOfMeasure === SystemOfMeasure.Metric &&
    //only digits. e.g. 90
    !/^\d+$/.test(value)
  ) {
    return Promise.reject(new Error('Please enter correct weight (digits only)'));
  }

  return Promise.resolve();
}
