import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { PurchaseInfo } from '../../../../dataTypes';
import { OrderItemList } from '../OrderItemList';
import { PurchaseTotals } from './components/PurchaseTotals';
import { DetailsItemBlock } from '../DetailsItemBlock';
import styles from './PurchaseView.module.scss';

export interface PurchaseViewProps {
  purchase?: PurchaseInfo;
}

export const PurchaseView: FC<PurchaseViewProps> = observer(({ purchase }) => {
  if (!purchase) {
    return null;
  }

  return (
    <div className={styles.container}>
      {purchase.purchasedItems.length > 0 && (
        <DetailsItemBlock title="Purchased items" className={styles.items1}>
          <OrderItemList items={purchase.purchasedItems} />
        </DetailsItemBlock>
      )}
      {purchase.returnedItems.length > 0 && (
        <DetailsItemBlock title="Items to return" className={styles.items2}>
          <OrderItemList items={purchase.returnedItems} />
        </DetailsItemBlock>
      )}
      <DetailsItemBlock title="Totals" className={styles.totals}>
        <PurchaseTotals totals={purchase.totals} />
      </DetailsItemBlock>
    </div>
  );
});
