import { ChangeEvent, useCallback } from 'react';

type Lens<T, V> = (input: T) => V;

export const eventLens: Lens<ChangeEvent<HTMLInputElement>, number> = (event) =>
  parseInt(event.target.value, 10);
export const simpleLens: Lens<number, number> = (value) => value;

export const useCallbackWithLens = <T, V>(
  callback: (args: V) => void,
  lens: Lens<T, V>,
): ReturnType<typeof useCallback> => {
  return useCallback((value: T) => callback(lens(value)), [callback, lens]);
};
