import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import { QuestionnaireItem } from '../../../../dataTypes';

interface Props extends PropsWithChildren<unknown> {
  item: QuestionnaireItem;
  name: string | number | React.ReactText[];
  rules?: Rule[];
}

export const deserializeFromEvent =
  (question: string) =>
  (event: ChangeEvent<HTMLInputElement>): QuestionnaireItem => {
    return {
      question,
      answers: [event.currentTarget.value],
    };
  };

export const serializeToInput = (value: QuestionnaireItem): { value: string } => {
  return {
    value: value.answers[0],
  };
};

export const QuestionnaireFormItem: React.FC<Props> = ({ item, name, children, rules }: Props) => (
  <Form.Item
    getValueFromEvent={deserializeFromEvent(item.question)}
    getValueProps={serializeToInput}
    label={item.question}
    wrapperCol={{ span: 5 }}
    name={name}
    rules={rules}
  >
    {children}
  </Form.Item>
);
