import { Order } from '../../../../dataTypes';
import {
  showOrderUpdateErrorMessage,
  showOrderUpdateSuccessMessage,
} from '../../../../components/Messages';
import { useInject } from '../../../../providers/DIProvider';
import { OrderStore } from '../../stores';
import { useGetGoToDetailsCallback } from '../../hooks/useGetGoToDetailsCallback';
import { useCallback } from 'react';

export const useOnFinish = (): ((order: Order) => Promise<void>) => {
  const { updateOrder } = useInject(OrderStore);
  const goToDetailsPage = useGetGoToDetailsCallback();
  return useCallback(
    (order: Order): Promise<void> => {
      return updateOrder(order, {
        onError: showOrderUpdateErrorMessage,
        onSuccess: () => {
          showOrderUpdateSuccessMessage();
          goToDetailsPage();
        },
      });
    },
    [goToDetailsPage, updateOrder],
  );
};
