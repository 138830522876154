import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { DIProvider } from './providers/DIProvider';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { shouldRetryRequest } from './utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: shouldRetryRequest,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <DIProvider>
      <Auth0ProviderWithHistory>
        <Router>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Router>
      </Auth0ProviderWithHistory>
    </DIProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
