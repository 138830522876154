import React, { ReactNode } from 'react';
import { FC, PropsWithChildren } from 'react';
import { HeaderComponent } from '../../../../components';
import { StatusViewManager } from '../../status-views';
import { OrderStatus } from '../../../../dataTypes';
import { StatusBreadcrumbs } from '../StatusBreadcrumbs/StatusBreadcrumbs';

interface OrderLayoutProps extends PropsWithChildren<unknown> {
  title: string;
  salesforceLink?: string;
  status: OrderStatus;
  buttons: ReactNode;
  hideStatusManager?: boolean;
}

export const PageLayout: FC<OrderLayoutProps> = ({
  children,
  title,
  status,
  buttons,
  salesforceLink,
  hideStatusManager,
}: OrderLayoutProps) => (
  <div>
    <HeaderComponent title={title} extra={buttons} salesforceLink={salesforceLink} />
    <StatusBreadcrumbs status={status} />
    {!hideStatusManager && <StatusViewManager />}
    <div>{children}</div>
  </div>
);
