import React, { FC } from 'react';

export const SendShipmentSvg: FC = () => (
  <svg
    enableBackground="new 0 0 24 24"
    height="1em"
    viewBox="0 0 24 24"
    width="1em"
    fill={'currentColor'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m17.5 11c-3.584 0-6.5 2.916-6.5 6.5s2.916 6.5 6.5 6.5 6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm2.817 5.241-3.25 3.75c-.137.157-.332.251-.54.258-.009 0-.018 0-.027 0-.199 0-.39-.079-.53-.22l-1.75-1.75c-.293-.293-.293-.768 0-1.061s.768-.293 1.061 0l1.181 1.18 2.722-3.141c.271-.312.744-.347 1.058-.076.312.274.346.747.075 1.06z" />
    <path d="m9.22 0-4.86.02c-.63 0-1.22.35-1.52.9l-2.54 4.6 8.43-.02z" />
    <path d="m19.68 5.48-2.57-4.6c-.31-.55-.89-.9-1.53-.9l-4.86.02.51 5.5z" />
    <path d="m20 9.37c-.79-.24-1.63-.37-2.5-.37-4.69 0-8.5 3.81-8.5 8.5 0 .87.13 1.72.38 2.51l-7.77.02c-.01 0-.01 0-.01 0-.86 0-1.57-.71-1.57-1.58l-.04-11.43 9.25-.02h1.5l9.25-.03z" />
  </svg>
);
