import { useQuery, UseQueryResult } from 'react-query';
import { useInject } from '../../../providers/DIProvider';
import { ApiService, OrderReturn } from '../../../services';

/* istanbul ignore file */

export function useOrderReturn(orderId: string): UseQueryResult<OrderReturn> {
  const apiService = useInject(ApiService);

  return useQuery(['return', orderId], async () => {
    return await apiService.getOrderReturn(orderId);
  });
}
