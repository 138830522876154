import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './BillingAddressStatusBadge.module.scss';

interface Props {
  verified: boolean;
}

export const BillingAddressStatusBadge: FC<Props> = ({ verified }) => {
  const text = verified ? 'Has billing address' : 'No billing address';

  return (
    <div
      className={classNames({
        [styles.state]: true,
        [styles.verified]: verified,
        [styles.notVerified]: !verified,
      })}
    >
      {text}
    </div>
  );
};
