import React, { FC, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DetailsItemBlock } from '../DetailsItemBlock';
import { OrderItemList } from '../OrderItemList';
import { useOrderStore } from 'pages/OrderDetails/hooks/useOrderStore';
import { Button, Card, Input } from 'antd';
import { getShortCurrencyString } from 'utils';
import LogoIcon from 'images/Logo.svg';

import styles from './ChargeRequestCreate.module.scss';
import { calculateFullPrice, calculateTotals } from './calculateTotals';
import { useRequestCharge } from './useRequestCharge';

export const TITLE = 'Please specify how much to charge customer for:';

interface IChargeRequestCreate {
  onCancel(): void;
}

const ChargeRequestCreate: FC<IChargeRequestCreate> = observer(({ onCancel }) => {
  const { execute } = useRequestCharge();

  const {
    currentOrder: { items },
  } = useOrderStore();

  if (!items) {
    return null;
  }

  const initialFullPrice = calculateFullPrice(items) / 2;
  const [totals, setTotals] = useState(calculateTotals(items, initialFullPrice));

  const handleChangeChargeAmount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const chargeAmount = Number(e.target.value);
      chargeAmount > 0 && setTotals(calculateTotals(items, chargeAmount));
    },
    [items],
  );

  const handleRequestChargeClick = (): void => {
    execute(totals.chargeAmount);
  };

  return (
    <div>
      <DetailsItemBlock title="Order items">
        <OrderItemList items={items} />
      </DetailsItemBlock>
      <Card className={styles.host}>
        <div className={styles.hostTitle}>{TITLE}</div>
        <div className={styles.inputWrapper}>
          <div className={styles.inputWrapperSpan}>Charge Amount</div>
          <Input
            addonBefore="£"
            data-testid="charge-amount"
            type="number"
            size={'large'}
            value={totals.chargeAmount}
            onChange={handleChangeChargeAmount}
          />
          <div className={styles.inputCurrency}>GBP</div>
        </div>
        <div className={styles.sliderInfo}>
          <div className={styles.sliderInfoBlock}>
            <div className={styles.sliderInfoLine}>
              <span>Cost Price</span>
              <strong>{getShortCurrencyString(totals.costPrice)}</strong>
            </div>
            <div className={styles.sliderInfoLine}>
              <span>Full Price</span>
              <strong>{getShortCurrencyString(totals.fullPrice)}</strong>
            </div>
          </div>
          <div className={styles.sliderInfoBlock}>
            <div className={styles.sliderInfoLine}>
              <span>Discount</span>
              <strong>{`${totals.discountPercentage}% Off`}</strong>
            </div>
            <div className={styles.sliderInfoLine}>
              <span>Savings</span>
              <strong>{getShortCurrencyString(totals.discountAmount)}</strong>
            </div>
          </div>
        </div>
      </Card>

      <div className={styles.buttonWrapper}>
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={handleRequestChargeClick}
          className={styles.button}
        >
          <img src={LogoIcon} alt="" />
          <span>Request</span>
        </Button>

        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={onCancel}
          className={styles.button}
        >
          <span>Cancel</span>
        </Button>
      </div>
    </div>
  );
});

export default ChargeRequestCreate;
