import { Select } from 'antd';
import React from 'react';

export const CategoryOptions = [
  'Coats',
  'Jackets & Blazers',
  'Trousers & Jeans',
  'Knitwear & Sweats',
  'Shirts',
  'Polo Shirts',
  'Rugby Shirts',
  'Suits',
  'Swimwear',
  'Underwear & Socks',
  'Shorts',
  'Shoes',
].map((value) => (
  <Select.Option value={value} key={value}>
    {value}
  </Select.Option>
));
