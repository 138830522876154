import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Space } from 'antd';
import { useOrderStore } from '../../hooks/useOrderStore';
import { OrderItemList } from '../../components/OrderItemList';
import { TrackShipmentButton } from '../../components/TrackShipmentButton';
import { DeliveredOrderButton } from '../../components/DeliveredOrderButton';
import styles from './DispatchedStatusView.module.scss';

export const DispatchedStatusView: FC = observer(() => {
  const { currentOrder } = useOrderStore();

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Space>
          <TrackShipmentButton />
          <DeliveredOrderButton />
        </Space>
      </div>
      <OrderItemList items={currentOrder.items} />
    </>
  );
});
