import React, { FC } from 'react';
import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ORDER_RECEIPT_URL } from '../../../../../../consts';
import { generatePath } from 'react-router-dom';

/* istanbul ignore file */

interface Props {
  orderId: string;
}

export const OrderReceiptButton: FC<Props> = (props) => {
  return (
    <Button
      type={'primary'}
      shape={'round'}
      size={'large'}
      href={generatePath(ORDER_RECEIPT_URL, { id: props.orderId })}
      target={'_blank'}
      style={{ marginRight: 8 }}
    >
      <PrinterOutlined />
      Order receipt
    </Button>
  );
};
