import React, { FC } from 'react';
import { Button } from 'antd';
import { useOrderStore } from '../../hooks/useOrderStore';
import { useOrderShipment } from '../../serverCache/useOrderShipment';

/* istanbul ignore file */

export const TrackShipmentButton: FC = () => {
  const { currentOrder } = useOrderStore();

  const { data } = useOrderShipment(currentOrder.id);

  return (
    <Button
      type="primary"
      shape="round"
      size="large"
      disabled={!data?.trackingLink}
      href={data?.trackingLink}
      target="_blank"
    >
      Track shipment
    </Button>
  );
};
