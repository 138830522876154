import React, { FC } from 'react';
import { StopOutlined } from '@ant-design/icons/lib/icons';
import { useCancelOrder } from './useCancelOrder';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const CancelOrderButton: FC = () => {
  const { execute, loading } = useCancelOrder();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to cancel this order?"
    >
      <StopOutlined />
      Cancel
    </ConfirmableButton>
  );
};
