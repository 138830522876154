import { useEffect } from 'react';
import { OrderFetchable } from '../stores';
import { useGetOrderId } from './useGetOrderId';

export const useLoadOrder = ({ fetchOrder }: OrderFetchable): void => {
  const id = useGetOrderId();
  useEffect(() => {
    fetchOrder(id);
  }, [id, fetchOrder]);
};
