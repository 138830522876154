import { generatePath } from 'react-router-dom';
import { singleton } from 'tsyringe';
import { PRICE_CALCULATION_SETTINGS_URL } from '../../consts';
import { ApiWrapper, ApiWrapperInstance } from '../ApiWrapper';
import { PriceCalculationSettings } from './dataTypes';

@singleton()
export class SettingsApiService {
  constructor(private readonly apiWrapper: ApiWrapper) {}

  private get api(): ApiWrapperInstance {
    return this.apiWrapper.instance;
  }

  private static generatePriceCalculationSettingsUrl(): string {
    return generatePath(PRICE_CALCULATION_SETTINGS_URL);
  }

  async getPriceCalculationSettings(): Promise<PriceCalculationSettings> {
    const priceSettingsResponse = (
      await this.api.get<PriceCalculationSettings>(
        SettingsApiService.generatePriceCalculationSettingsUrl(),
      )
    ).data;

    return priceSettingsResponse;
  }
}
