import { OrderResponse } from '../services';
import { Gender, Order, OrderStatus } from '../dataTypes';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { dateTimeFormat, timeFormat } from '../constants';
import { AxiosError } from 'axios';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

export function mapResponseToOrder(orderResponse: OrderResponse): Order {
  return {
    ...orderResponse,
    stylingFor: orderResponse.stylingFor.toLowerCase(),
    status: OrderStatus[orderResponse.status as keyof typeof OrderStatus],
    lastModifiedDate: dayjs(orderResponse.lastModifiedDate),
    stylingProfile: {
      ...orderResponse.stylingProfile,
      dateOfBirth: dayjs(orderResponse.stylingProfile.dateOfBirth),
    },
    communicationDateTime: orderResponse.communicationDateTime ?? {},
  } as Order;
}

export function getAgeString(dateOfBirth: Dayjs, gender: Gender): string {
  const difference = dayjs().diff(dateOfBirth, 'month');
  const years = Math.floor(difference / 12);
  const months = difference % 12;

  if (gender === 'Male' || months === 0) {
    return `${years} y.o.`;
  } else {
    return `${years}y. ${months}mo.`;
  }
}

export function getDateString(date: Dayjs | undefined): string | undefined {
  if (date) {
    if (date.isToday()) {
      return `Today, ${date.format(timeFormat)}`;
    }

    if (date.isYesterday()) {
      return `Yesterday, ${date.format(timeFormat)}`;
    }

    return date.format(dateTimeFormat);
  }

  return undefined;
}

export function getCurrencyString(amount: number): string {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount);
}

export function getShortCurrencyString(amount: number): string {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

export function getPercentageString(percentage: number): string {
  return new Intl.NumberFormat('en-GB', { style: 'percent', maximumFractionDigits: 2 }).format(
    percentage,
  );
}

export function getEnumKeyByValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);

  if (keys.length > 0) {
    return keys[0];
  }

  throw Error('Enum member cannot be found by value');
}

export function isAxiosError<T>(e: unknown): e is AxiosError<T> {
  return (e as AxiosError).isAxiosError;
}

export function shouldRetryRequest(failureCount: number, error: unknown): boolean {
  if (isAxiosError(error) && (error.response?.status ?? 0) < 500) {
    return false;
  }

  return failureCount <= 4;
}

export function sum(a: number, b: number): number {
  return a + b;
}
