/* istanbul ignore file */
import { Auth0ProviderOptions } from '@auth0/auth0-react';
import assert from 'assert';

export const getAuthProviderOptions = (): Auth0ProviderOptions => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  assert(domain, 'domain field is mandatory');
  assert(clientId, 'clientId is mandatory');
  assert(audience, 'audience is mandatory');

  return { domain, audience, clientId };
};
