import {
  showUpdatePaymentMethodErrorMessage,
  showUpdatePaymentMethodSuccessMessage,
} from 'components/Messages/showPaymentMethodMessages';
import { PaymentMethod } from 'dataTypes';
import { useGetGoToDetailsCallback } from 'pages/OrderDetails/hooks/useGetGoToDetailsCallback';
import { useUpdatePaymentMethod } from 'pages/OrderDetails/serverCache/useOrderPaymentMethods';
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';

export function useOnPaymentMethodSubmit(
  paymentMethodId: string,
): UseAsyncReturn<void, [Partial<PaymentMethod>]> {
  const { mutateAsync: updatePaymentMethod } = useUpdatePaymentMethod();

  const goToDetailsPage = useGetGoToDetailsCallback();

  return useAsyncCallback(async (paymentMethod: Partial<PaymentMethod>): Promise<void> => {
    try {
      await updatePaymentMethod({ id: paymentMethodId, payload: paymentMethod });
      showUpdatePaymentMethodSuccessMessage();
      goToDetailsPage();
    } catch (e) {
      showUpdatePaymentMethodErrorMessage();
    }
  });
}
