import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/* istanbul ignore file */

export const useGetGoBackCallback = (): (() => void) => {
  const history = useHistory();
  return useCallback((): void => {
    history.goBack();
  }, [history]);
};
