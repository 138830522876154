/* istanbul ignore file */

export const PAGE_QUERY_PARAM_NAME = 'page';

export function getPageNumber(search: URLSearchParams): number {
  const pageNumberString = search.get(PAGE_QUERY_PARAM_NAME);
  if (!pageNumberString) {
    return 1;
  }

  const pageNumber = Number.parseInt(pageNumberString);
  return Number.isNaN(pageNumber) ? 1 : pageNumber;
}

export function setPageNumber(search: URLSearchParams, pageNumber: number): URLSearchParams {
  const newSearch = new URLSearchParams(search);
  newSearch.set(PAGE_QUERY_PARAM_NAME, `${pageNumber}`);
  return newSearch;
}

export function clearPageNumber(search: URLSearchParams): URLSearchParams {
  const newSearch = new URLSearchParams(search);
  newSearch.delete(PAGE_QUERY_PARAM_NAME);
  return newSearch;
}
