import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { PurchasedOrder } from '../../../../dataTypes';
import { useOrderStore } from '../../hooks/useOrderStore';
import { Space } from 'antd';
import { CompleteOrderButton } from '../../components/CompleteOrderButton';
import { TrackReturnButton } from '../../components/TrackReturnButton';
import { PurchaseView } from '../../components/PurchaseView';

import styles from './PendingReturnStatusView.module.scss';
import { OrderItemList } from '../../components/OrderItemList';
import useConfig from 'hooks/useConfig';
import { CaptureDepositButton } from 'pages/OrderDetails/components/CaptureDepositButton';

export const PendingReturnStatusView: FC = observer(() => {
  const { currentOrder } = useOrderStore();
  const depositAvailable =
    currentOrder.deposits && currentOrder.deposits.some((el) => el.status === 'Active');
  const { config } = useConfig();

  const captureDepositButton = config?.allowCaptureDeposit ? <CaptureDepositButton /> : null;

  const purchasedOrder = currentOrder as Partial<PurchasedOrder>;

  return (
    <>
      <div className={styles.buttonsContainer}>
        <Space>
          {depositAvailable && captureDepositButton}
          <TrackReturnButton />
          <CompleteOrderButton />
        </Space>
      </div>
      {purchasedOrder.purchase && <PurchaseView purchase={purchasedOrder.purchase} />}
      {!purchasedOrder.purchase && <OrderItemList items={currentOrder.items} />}
    </>
  );
});
