export const dateOfBirthFormat = 'DD/MM/YYYY';

export const timeFormat = 'h:mm A';

export const dateTimeFormat = 'D MMM, h:mm A';

export const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

export const phoneRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]{1,20}$/;

// zigzag requirement
export const noAngleBracketsRegex = /^[^<>]*$/;
