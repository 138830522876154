import { useQuery, UseQueryResult } from 'react-query';
import { useInject } from 'providers/DIProvider';
import { ApiService } from 'services';
import { ChargeRequest } from 'dataTypes';

export function useGetChargeRequest(id: string): UseQueryResult<ChargeRequest> {
  const apiService = useInject(ApiService);

  return useQuery(['charge-request'], async () => {
    try {
      return await apiService.getChargeRequest(id);
    } catch {
      return undefined;
    }
  });
}
