import { singleton } from 'tsyringe';
import {
  makeGetOrderShipmentLabelUrl,
  makeGetOrderShipmentUrl,
  makePostOrderShipmentUrl,
} from '../apiUrls';
import { ApiWrapper, ApiWrapperInstance } from '../ApiWrapper';
import { ShipmentResponse } from './ShipmentResponse';

/* istanbul ignore file */

@singleton()
export class ShipmentApiService {
  constructor(private readonly apiWrapper: ApiWrapper) {}

  private get api(): ApiWrapperInstance {
    return this.apiWrapper.instance;
  }

  async postShipment(orderId: string): Promise<ShipmentResponse> {
    const result = await this.api.post<ShipmentResponse>(makePostOrderShipmentUrl(orderId));

    return result.data;
  }

  async getShipmentInfo(orderId: string): Promise<ShipmentResponse> {
    const result = await this.api.get<ShipmentResponse>(makeGetOrderShipmentUrl(orderId));

    return result.data;
  }

  async fetchShipmentStatus(orderId: string): Promise<boolean> {
    try {
      const data = await this.getShipmentInfo(orderId);

      return !!data;
    } catch (e) {
      return false;
    }
  }

  async getShipmentLabel(orderId: string): Promise<void> {
    await this.api
      .get<ArrayBuffer>(makeGetOrderShipmentLabelUrl(orderId), {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `label_${orderId}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  }
}
