import { Order } from 'dataTypes';
import { singleton } from 'tsyringe';
import {
  makePerformChargeUrl,
  makeRequestChargeUrl,
  makeRevertOrderToDeliveredUrl,
} from './apiUrls';
import { ApiWrapper, ApiWrapperInstance } from './ApiWrapper';
import { OrderResponse } from './dataTypes';

/* istanbul ignore file */

@singleton()
export class OrderApiService {
  constructor(private readonly apiWrapper: ApiWrapper) {}

  private get api(): ApiWrapperInstance {
    return this.apiWrapper.instance;
  }

  async revertOrderToDelivered(orderId: string): Promise<OrderResponse> {
    const response = await this.api.post<OrderResponse>(makeRevertOrderToDeliveredUrl(orderId));
    return response.data;
  }

  async requestCharge(id: Order['id'], chargeAmount: number): Promise<OrderResponse> {
    const response = await this.api.post<OrderResponse>(makeRequestChargeUrl(id), { chargeAmount });
    return response.data;
  }

  async performCharge(id: Order['id']): Promise<OrderResponse> {
    const response = await this.api.post<OrderResponse>(makePerformChargeUrl(id));
    return response.data;
  }
}
