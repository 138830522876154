import { SelectQuestion } from 'dataTypes';

export const kidsQuestions = [
  new SelectQuestion('Top Half', [
    '',
    '6 months',
    '1-2 years',
    '2-3 years',
    '3-4 years',
    '5-6 years',
    '7-8 years',
    '9-10 years',
    '11-12 years',
    '13-14 years',
    '15-16 years',
  ]),

  new SelectQuestion('Bottom Half', [
    '',
    '6 months',
    '1-2 years',
    '2-3 years',
    '3-4 years',
    '5-6 years',
    '7-8 years',
    '9-10 years',
    '11-12 years',
    '13-14 years',
    '15-16 years',
  ]),

  new SelectQuestion('UK Shoe Size', [
    '',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
  ]),
];
