import notification from 'antd/lib/notification';
import { PURCHASE_ERROR_MESSAGE } from '../../consts';

export const showPurchaseErrorMessage = (errorDescription: string): void => {
  /* istanbul ignore next */
  notification.error({
    message: PURCHASE_ERROR_MESSAGE,
    description: errorDescription,
  });
};
