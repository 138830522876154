import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { PriceCalculationSettings } from '../../../services/Settings/dataTypes';
import { SettingsApiService } from '../../../services/Settings/SettingsApiService';
import { LoadingStatuses, OrderItem } from '../../../dataTypes';
import { INCORRECT_SELL_PRICE, NO_ITEMS_SELECTED } from '../../../consts';
import { sum } from 'utils';

export interface CalculatedPrices {
  minPrice: number;
  stylistCommissionThreshold: number;
  fullPrice: number;
  savingsToCustomer: number;
  discountToCustomer: number;
  stylistCommissionAmount: number;
}

@singleton()
export class PriceCalculationStore {
  settings!: PriceCalculationSettings;
  loadingSettingsStatus = LoadingStatuses.Init;

  purchasedItems: OrderItem[] = [];
  sellPrice = 0;

  constructor(private apiService: SettingsApiService) {
    makeAutoObservable(this);
  }

  loadSettingsIfNotLoaded(): Promise<void> {
    if (
      this.loadingSettingsStatus !== LoadingStatuses.InProgress &&
      this.loadingSettingsStatus !== LoadingStatuses.Finished
    ) {
      return this.loadPriceCalculationSettings();
    }
    return Promise.resolve();
  }

  private async loadPriceCalculationSettings(): Promise<void> {
    this.setLoadingSettingsStatus(LoadingStatuses.InProgress);
    try {
      const response = await this.apiService.getPriceCalculationSettings();
      this.setSettings(response);
      this.setLoadingSettingsStatus(LoadingStatuses.Finished);
    } catch (e) {
      this.setLoadingSettingsStatus(LoadingStatuses.Error);
      throw e;
    }
  }

  setSettings(settings: PriceCalculationSettings): void {
    this.settings = settings;
  }

  setLoadingSettingsStatus(status: LoadingStatuses): void {
    this.loadingSettingsStatus = status;
  }

  setPurchasedItems(items: OrderItem[]): void {
    this.purchasedItems = items;

    if (this.sellPrice < this.minPrice) {
      this.sellPrice = this.minPrice;
    }
  }

  validateItemsPurchased(itemsPurchased: OrderItem[]): string | undefined {
    if (!itemsPurchased.length) {
      return NO_ITEMS_SELECTED;
    }

    return;
  }

  setSellPrice(price: number): void {
    this.sellPrice = price;
  }

  validateSellPrice(sellPrice: number): string | undefined {
    if (!sellPrice || sellPrice < this.minPrice) {
      return INCORRECT_SELL_PRICE + this.minPrice.toFixed(2);
    }

    return;
  }

  validateStoreState(): string | undefined {
    return (
      this.validateItemsPurchased(this.purchasedItems) ?? this.validateSellPrice(this.sellPrice)
    );
  }

  get calculatedPrices(): CalculatedPrices {
    this.ensureSettingsLoaded();

    return {
      minPrice: this.minPrice,
      fullPrice: this.fullPrice,
      discountToCustomer: this.discountToCustomer,
      savingsToCustomer: this.savingsToCustomer,
      stylistCommissionAmount: this.stylistCommissionAmount,
      stylistCommissionThreshold: this.stylistCommissionThreshold,
    };
  }

  get minPrice(): number {
    this.ensureSettingsLoaded();
    return Math.round(
      this.purchasedItems.map((i) => i.costPrice).reduce(sum, 0) *
        (1 + this.settings.costPriceSurcharge),
    );
  }

  get stylistCommissionThreshold(): number {
    this.ensureSettingsLoaded();
    return Math.round(this.minPrice * this.settings.multiplierToEarnCommission);
  }

  get fullPrice(): number {
    this.ensureSettingsLoaded();
    return this.purchasedItems.map((i) => i.choixcePrice).reduce(sum, 0);
  }

  private ensureSettingsLoaded(): void {
    if (this.loadingSettingsStatus !== LoadingStatuses.Finished) {
      throw new Error('Settings not loaded');
    }
  }

  get savingsToCustomer(): number {
    this.ensureSettingsLoaded();
    const savings = this.fullPrice - this.sellPrice;
    return isNaN(savings) ? 0 : savings;
  }

  get discountToCustomer(): number {
    this.ensureSettingsLoaded();
    return Math.round((this.savingsToCustomer / this.fullPrice) * 100);
  }

  get stylistCommissionAmount(): number {
    this.ensureSettingsLoaded();
    const result = Math.ceil(
      (this.sellPrice - this.stylistCommissionThreshold) * this.settings.stylistInterest,
    );

    return isNaN(result) || result < 0 ? 0 : result;
  }
}
