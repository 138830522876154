import { getEnumKeyByValue } from '../../../utils';
import { OrderStatus } from '../../../dataTypes';

/* istanbul ignore file */

export const STATUSES_QUERY_PARAM_NAME = 'statuses';

export function getStatuses(search: URLSearchParams): string[] {
  const statusesString = search.get(STATUSES_QUERY_PARAM_NAME);
  if (!statusesString) {
    return [
      getEnumKeyByValue(OrderStatus, OrderStatus.Submitted),
      getEnumKeyByValue(OrderStatus, OrderStatus.ReadyForPicking),
      getEnumKeyByValue(OrderStatus, OrderStatus.Dispatched),
      getEnumKeyByValue(OrderStatus, OrderStatus.Delivered),
      getEnumKeyByValue(OrderStatus, OrderStatus.DeliveryOverdue),
      getEnumKeyByValue(OrderStatus, OrderStatus.PendingPayment),
      getEnumKeyByValue(OrderStatus, OrderStatus.PaymentOverdue),
      getEnumKeyByValue(OrderStatus, OrderStatus.PendingReturn),
      getEnumKeyByValue(OrderStatus, OrderStatus.ReturnOverdue),
    ];
  }

  return statusesString.split(',');
}

export function setStatuses(search: URLSearchParams, statuses: string[]): URLSearchParams {
  const newSearch = new URLSearchParams(search);
  if (statuses.length) {
    newSearch.set(STATUSES_QUERY_PARAM_NAME, statuses.join(','));
  } else {
    newSearch.delete(STATUSES_QUERY_PARAM_NAME);
  }
  return newSearch;
}
