import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { PurchasedOrder } from '../../../../dataTypes';
import { useOrderStore } from '../../hooks/useOrderStore';
import { PurchaseView } from '../../components/PurchaseView';
import ChargeRequestView from 'components/ChargeRequestView';
import { useGetChargeRequest } from 'hooks/useGetChargeRequest';

export const CHARGE_INFO_TITLE = 'This order has been charged';

export const CompletedStatusView: FC = observer(() => {
  const { currentOrder } = useOrderStore();
  const purchasedOrder = currentOrder as PurchasedOrder;
  const { data: chargeRequest, status } = useGetChargeRequest(currentOrder.id);

  if (status === 'loading') {
    return null;
  }

  return (
    <>
      {chargeRequest ? (
        <ChargeRequestView title={CHARGE_INFO_TITLE} chargeRequest={chargeRequest} />
      ) : (
        <PurchaseView purchase={purchasedOrder.purchase} />
      )}
    </>
  );
});
