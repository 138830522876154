import { SelectQuestion } from 'dataTypes';

export const menQuestions = [
  new SelectQuestion('Tops', ['', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']),

  new SelectQuestion('Waist Size', [
    '',
    '28"',
    '29"',
    '30"',
    '31"',
    '32"',
    '33"',
    '34"',
    '35"',
    '36"',
    '37"',
    '38"',
    '39"',
    '40"',
    '41"',
    '42"',
  ]),

  new SelectQuestion('Leg Length', ['', '28"', '30"', '32"', '34"', '36"', 'I don’t know']),

  new SelectQuestion('UK Shoe Size', [
    '',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
  ]),

  new SelectQuestion('Neck Size', [
    '',
    '14.5"',
    '15"',
    '15.5"',
    '16"',
    '16.5"',
    '17"',
    '17.5"',
    'I don’t know',
  ]),

  new SelectQuestion('Blazer', [
    '',
    '36"',
    '37"',
    '38"',
    '39"',
    '40"',
    '41"',
    '42"',
    '43"',
    '44"',
    '45"',
  ]),
];
