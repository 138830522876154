import Icon from '@ant-design/icons';
import React, { FC } from 'react';
import { SendShipmentSvg } from './svgs/SendShipment';

/* istanbul ignore file */

type Props = React.ComponentProps<typeof Icon>;

export const SendShipment: FC<Props> = (props: Props) => (
  <Icon component={SendShipmentSvg} {...props} />
);
