import { Typography } from 'antd';
import React from 'react';

interface PriceInputProps {
  value?: string;
  id?: string;
}

export const NO_VALUE_MESSAGE = 'Is not set';

export const FormValue: React.FC<PriceInputProps> = ({ value, id }) => {
  if (!value) {
    return (
      <Typography.Text id={id} type="secondary">
        {NO_VALUE_MESSAGE}
      </Typography.Text>
    );
  }
  return <span id={id}>{value}</span>;
};
