import React, { FC, Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { Skeleton, Table } from 'antd';
import { useOrderList } from './serverCache/useOrderList';
import { useLocationSearch } from '../../hooks/useLocationSearch';
import { OrderListItem, OrderStatus } from '../../dataTypes';
import { TagComponent, TagType } from '../../components/TagComponent';
import { getAgeString, getDateString } from '../../utils';
import { HeaderComponent } from '../../components';
import { OrderStatusBadge } from '../../components/OrderStatusBadge';
import { StatusSelect } from './components/StatusSelect';
import styles from './OrderList.module.scss';
import { getPageNumber, setPageNumber, clearPageNumber } from './utils/pagintation';
import { getStatuses, setStatuses } from './utils/statuses';

/* istanbul ignore file */

const PAGE_SIZE = 10;

export const OrderList: FC = () => {
  const history = useHistory();

  const onRowClick = (item: OrderListItem): void => {
    history.push(`/orders/${item.id}`);
  };

  const search = useLocationSearch();
  const currentPageNumber = useMemo(() => getPageNumber(search), [search]);
  const statuses = useMemo(() => getStatuses(search), [search]);

  const { data, isLoading } = useOrderList(
    {
      pageNumber: currentPageNumber,
      pageSize: PAGE_SIZE,
    },
    statuses,
  );

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <HeaderComponent title="Orders" />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <HeaderComponent title="Orders" />
      <StatusSelect
        statuses={statuses}
        onChange={(selectedStatuses: string[]) => {
          const newSearch = clearPageNumber(setStatuses(search, selectedStatuses));
          history.push({
            search: newSearch.toString(),
          });
        }}
      />
      <Table
        dataSource={data?.items}
        rowKey="id"
        onRow={(item) => ({
          onClick: () => {
            onRowClick(item);
          },
        })}
        className={styles.table}
        rowClassName={styles.tableRow}
        loading={isLoading}
        pagination={{
          current: currentPageNumber,
          onChange: (pageNumber) => {
            const newSearch = setPageNumber(search, pageNumber);
            history.push({
              search: newSearch.toString(),
            });
          },
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
          total: data?.total,
        }}
      >
        <Table.Column
          title={<span className={styles.title}>Number</span>}
          dataIndex="id"
          key="id"
          className={styles.number}
          width={'5%'}
          render={(text: string, record: OrderListItem) => {
            return {
              props: {
                className: styles.number,
              },
              children: text,
            };
          }}
        />
        <Table.Column
          title={<span className={styles.title}>Styling for</span>}
          dataIndex="name"
          key="name"
          className={styles.stylingFor}
          width={'10%'}
          render={(text: string, record: OrderListItem) => {
            return {
              props: {
                className: styles.stylingFor,
              },
              children: text,
            };
          }}
        />
        <Table.Column
          title={<span className={styles.title}>Gender</span>}
          dataIndex="gender"
          key="gender"
          className={styles.genderGlobal}
          render={(text: string, record: OrderListItem) => {
            return {
              props: {
                className: styles.gender,
              },
              children: text,
            };
          }}
        />
        <Table.Column
          title={<span className={styles.title}>Age</span>}
          dataIndex="dateOfBirth"
          key="dateOfBirth"
          render={(date: Dayjs, record: OrderListItem) => (
            <span>{getAgeString(date, record.gender)}</span>
          )}
          className={styles.age}
        />
        <Table.Column
          title={<span className={styles.title}>Looking for</span>}
          className={styles.lookingFor}
          dataIndex="intention"
          key="intention"
        />
        <Table.Column
          title={<span className={styles.title}>Clothing categories</span>}
          className={styles.clothing}
          dataIndex="clothingCategories"
          key="clothingCategories"
          render={(categories: string[]) => (
            <Fragment>
              {categories.slice(0, 4).map((value: string, idx) => (
                <TagComponent type={TagType.small} key={idx}>
                  {value}
                </TagComponent>
              ))}
              {categories.length > 4 && (
                <TagComponent type={TagType.small}>+{categories.length - 4}</TagComponent>
              )}
            </Fragment>
          )}
        />
        <Table.Column
          title={<span className={styles.title}>Status</span>}
          className={styles.status}
          dataIndex="status"
          key="status"
          render={(status: OrderStatus) => <OrderStatusBadge status={status} />}
        />
        <Table.Column
          title={<span className={styles.title}>Last update</span>}
          dataIndex="lastModifiedDate"
          className={styles.date}
          key="lastModifiedDate"
          defaultSortOrder="descend"
          render={(date: Dayjs) => (
            <span className={styles['dateSpan']}>{getDateString(date)}</span>
          )}
        />
        <Table.Column
          title={<span className={styles.title}>Created</span>}
          dataIndex="createdDate"
          className={styles.date}
          key="createDate"
          defaultSortOrder="descend"
          render={(date: Dayjs) => (
            <span className={styles['dateSpan']}>{getDateString(date)}</span>
          )}
        />
      </Table>
    </div>
  );
};
