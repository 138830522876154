import React, { FC } from 'react';
import { useInject } from '../../../../providers/DIProvider';
import { ShippingStore } from '../../stores/ShippingStore';
import { useBookAndPrint } from './useBookAndPrint';
import { PrinterOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';
import { Button } from 'antd';

interface Props {
  confirmationRequired: boolean;
  confirmationText: string;
}

export const PrintButton: FC<Props> = observer(({ confirmationRequired, confirmationText }) => {
  const shippingStore = useInject(ShippingStore);
  const { loading, execute } = useBookAndPrint();

  if (!shippingStore.isAbleToBookShipment) {
    return null;
  }

  const buttonContent = (
    <>
      <PrinterOutlined />
      {!loading ? <span>Print label</span> : 'Loading...'}
    </>
  );

  return confirmationRequired ? (
    <ConfirmableButton disabled={loading} confirmationText={confirmationText} onConfirm={execute}>
      {buttonContent}
    </ConfirmableButton>
  ) : (
    <Button
      data-testid={'print-label'}
      disabled={loading}
      type={'primary'}
      shape={'round'}
      size={'large'}
      onClick={execute}
    >
      {buttonContent}
    </Button>
  );
});
