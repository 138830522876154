import notification from 'antd/lib/notification';
import {
  CHARGE_REQUEST_SUCCESS_MESSAGE,
  CHARGE_REQUEST_SUCCESS_DESCRIPTION,
  CHARGE_REQUEST_ERROR_MESSAGE,
  CHARGE_REQUEST_ERROR_DESCRIPTION,
} from '../../consts';

export const showChargeRequestErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: CHARGE_REQUEST_ERROR_MESSAGE,
    description: CHARGE_REQUEST_ERROR_DESCRIPTION,
  });
};

export const showChargeRequestSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: CHARGE_REQUEST_SUCCESS_MESSAGE,
    description: CHARGE_REQUEST_SUCCESS_DESCRIPTION,
  });
};
