import React, { FC, PropsWithChildren } from 'react';
import { useInject } from '../../../../../../providers/DIProvider';
import { Space } from 'antd';
import { ShippingStore } from '../../../../stores/ShippingStore';
import { LoadingOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import styles from './OrderDeliveryWidget.module.scss';
import { useAsync } from 'react-async-hook';

export const OrderDeliveryWidget: FC<PropsWithChildren<unknown>> = observer(({ children }) => {
  const shippingStore = useInject(ShippingStore);

  useAsync(() => shippingStore.initialize(), [shippingStore]);

  if (!shippingStore.isReady) {
    return <LoadingOutlined />;
  }

  return (
    <div className={styles.host}>
      <Space>{children}</Space>
    </div>
  );
});
