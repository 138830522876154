import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './SliderMark.module.scss';
import { Tooltip } from 'antd';

export enum SliderMarkTypes {
  Red = 'Red',
  Green = 'Green',
  Grey = 'Grey',
}

interface Props {
  value: string | number;
  type?: SliderMarkTypes;
  title?: string;
}

export const SliderMark: FC<Props> = ({ value, type = SliderMarkTypes.Grey, title }) => {
  return (
    <div
      className={classNames({
        [styles.green]: type === SliderMarkTypes.Green,
        [styles.red]: type === SliderMarkTypes.Red,
        [styles.grey]: type === SliderMarkTypes.Grey,
        [styles.host]: true,
      })}
    >
      <Tooltip title={title}>{value}</Tooltip>
    </div>
  );
};
