/* istanbul ignore file */
import React, { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { getAuthProviderOptions } from './getAuthProviderOptions';

const Auth0ProviderWithHistory: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = ({ returnTo }: AppState): void => {
    history.push(returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      {...getAuthProviderOptions()}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
