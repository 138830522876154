import { generatePath, useHistory } from 'react-router-dom';
import { useCallback } from 'react';

export const useGetGoToCallback = (
  pattern: string,
  params?: { [paramName: string]: string | number | boolean | undefined },
): (() => void) => {
  const history = useHistory();
  return useCallback((): void => {
    history.push(generatePath(pattern, params));
  }, [params, history, pattern]);
};
