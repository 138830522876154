import { RuleRender } from 'antd/lib/form';
import { QuestionnaireItem, SystemOfMeasure } from '../../../../dataTypes';

export function generateHeightRule(systemOfMeasure: SystemOfMeasure): RuleRender {
  return () => ({
    validator(_, itemValue: QuestionnaireItem) {
      const value = itemValue.answers[0];
      return validateHeightFormat(value, systemOfMeasure);
    },
  });
}

export function validateHeightFormat(
  value: string | undefined,
  systemOfMeasure: SystemOfMeasure,
): Promise<void> {
  if (!value) {
    return Promise.resolve();
  }

  if (
    systemOfMeasure === SystemOfMeasure.Imperial &&
    //e.g. 2'3.5"
    !/^(\d)'((\d|(10)|(11))(\.5)?")?$/.test(value)
  ) {
    return Promise.reject(new Error('Please enter correct value, e.g. 2\'3.5"'));
  }

  if (
    systemOfMeasure === SystemOfMeasure.Metric &&
    //only digits. e.g. 190
    !/^\d+$/.test(value)
  ) {
    return Promise.reject(new Error('Please enter correct height (digits only)'));
  }

  return Promise.resolve();
}
