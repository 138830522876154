import React, { FC } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons/lib/icons';
import { useSetOrderReadyForPicking } from './useSetOrderReadyForPicking';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const ReadyForPickingButton: FC = () => {
  const { execute, loading } = useSetOrderReadyForPicking();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="No deposit has been taken. Are you sure this order is ready for picking?"
    >
      <ArrowRightOutlined />
      Ready for Picking
    </ConfirmableButton>
  );
};
