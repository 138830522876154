import React, { FC } from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons/lib/icons';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';
import { useTakePayment } from './useTakePayment';

export const TakePaymentButton: FC = () => {
  const { execute, loading } = useTakePayment();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to take payment on behalf of the customer?"
    >
      <ShoppingCartOutlined />
      Take payment
    </ConfirmableButton>
  );
};
