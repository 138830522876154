import { FC } from 'react';
import { PoundOutlined } from '@ant-design/icons/lib/icons';
import { useCaptureDeposit } from './useCaptureDeposit';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const CaptureDepositButton: FC = () => {
  const { execute, loading } = useCaptureDeposit();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to capture customer's deposit?"
    >
      <PoundOutlined />
      Capture Deposit
    </ConfirmableButton>
  );
};
