import React, { FC } from 'react';
import { useGetGoToEditCallback } from '../../hooks/useGetGoToEditCallback';
import { GoBackButton } from '../../components/GoBackButton';
import { DetailsItemBlock } from '../../components/DetailsItemBlock';
import { PageLayout } from '../../components/PageLayout';
import { Button, Form, Space } from 'antd';
import { FormatText } from '../../components/form/FormatText';
import { NO_COMMENTS_PROVIDED } from '../../../../consts';
import { QuestionnaireFormItem } from '../../components/form/QuestionnaireFormItem';
import { Order, PaymentMethod, QuestionnaireItem } from '../../../../dataTypes';
import {
  orderFormConfig as cfg,
  OrderFormFields as Fields,
} from '../OrderEditForm/orderFormConfig';
import { formProps } from '../OrderEditForm';
import { FormValue } from '../../components/form/FormValue';
import { FormatDate } from '../../components/form/FormDate';
import { FormatCategories } from '../../components/form/FormatCategories';
import { OrderStatusBadge } from '../../../../components/OrderStatusBadge';
import { ButtonWrapper } from '../../../../components/ButtonWrapper/ButtonWrapper';
import { OrderLayoutGrid } from '../../components/OrderLayoutGrid';
import { useInject } from '../../../../providers/DIProvider';
import { OrderStore } from '../../stores';
import styles from './OrderView.module.scss';
import { useOrderPaymentMethods } from '../../serverCache/useOrderPaymentMethods';
import { CreditCardVerificationStatusBadge } from '../../../../components/CreditCardVerificationStatusBadge';
import { BillingAddressStatusBadge } from 'components/BillingAddressStatusBadge';
import { useGetGoToPaymentMethodCallback } from 'pages/OrderDetails/hooks/useGetGoToPaymentMethodCallback';
import { DepositStatusBadge } from '../../../../components/DepositStatusBadge';
import { sum } from 'utils';

export interface OrderViewProps {
  order: Order;
}

const hasPaymentMethodBillingAddress = (paymentMethods?: PaymentMethod[]): boolean => {
  if (!paymentMethods || paymentMethods.length === 0) {
    return false;
  }

  const defaultAddress = paymentMethods.find((pm) => pm.isDefault) || paymentMethods[0];

  return Boolean(
    defaultAddress.billingAddress &&
      defaultAddress.billingAddress.postcode &&
      defaultAddress.billingAddress.street,
  );
};

export const OrderView: FC = () => {
  const { currentOrder: order } = useInject<OrderStore>(OrderStore);
  const { stylingProfile } = order;
  const onGoToEdit = useGetGoToEditCallback();
  const onGoToPaymentmethod = useGetGoToPaymentMethodCallback();

  const { data: paymentMethodList } = useOrderPaymentMethods(order.id);

  const creditCardVerified =
    paymentMethodList !== undefined && paymentMethodList.paymentMethods.length > 0;

  const hasBillingAddress = hasPaymentMethodBillingAddress(paymentMethodList?.paymentMethods);

  const title = `${order.id} - ${stylingProfile.firstName} ${stylingProfile.lastName}`;

  const depositAmount = order.deposits?.map((el) => el.amount).reduce(sum, 0);
  const spentDepositAmount = order.deposits?.map((el) => el.amountSpent).reduce(sum, 0);
  const refundedDepositAmount = order.deposits?.map((el) => el.amountRefunded).reduce(sum, 0);

  return (
    <PageLayout
      title={title}
      status={order.status}
      buttons={<GoBackButton />}
      salesforceLink={order.salesforceOpportunityLink}
    >
      <Form {...formProps} initialValues={order}>
        <OrderLayoutGrid>
          <DetailsItemBlock title={'Status'}>
            <Space>
              <OrderStatusBadge status={order.status} />
              <CreditCardVerificationStatusBadge verified={creditCardVerified} />
              <DepositStatusBadge
                amount={depositAmount}
                amountSpent={spentDepositAmount}
                amountRefunded={refundedDepositAmount}
              />
              <BillingAddressStatusBadge verified={hasBillingAddress} />
              <Button onClick={onGoToPaymentmethod} type="dashed" shape="round" size="small">
                {hasBillingAddress ? 'Edit card details' : 'Add card details'}
              </Button>
            </Space>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Contact Details'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.PROFILE_FIRSTNAME]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_LASTNAME]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_PHONE]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.PROFILE_EMAIL]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_METHOD]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_DATE]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.COMMUNICATION_TIME]}>
              <FormValue />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Delivery Address'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.ADDRESS_NAME]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_PHONE]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_HOUSE]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_STREET]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_CITY]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_COUNTY]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.ADDRESS_POSTCODE]}>
              <FormValue />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Styling for'} className={styles.colInfo}>
            <Form.Item {...cfg[Fields.STYLING_FOR_FIRSTNAME]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_LASTNAME]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_BIRTHDAY]}>
              <FormatDate />
            </Form.Item>
            <Form.Item {...cfg[Fields.STYLING_FOR_GENDER]}>
              <FormValue />
            </Form.Item>
            <Form.Item {...cfg[Fields.INTENTION]}>
              <FormValue />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Clothing Categories'}>
            <Form.Item {...cfg.CATEGORIES}>
              <FormatCategories />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Size & fit'} className={styles.colInfo}>
            {order.stylingProfile.questionnaire.map((item: QuestionnaireItem, key) => (
              <QuestionnaireFormItem
                item={item}
                key={key}
                name={['stylingProfile', 'questionnaire', key]}
              >
                <FormValue />
              </QuestionnaireFormItem>
            ))}
            <Form.Item label="System of measure" name={['stylingProfile', 'systemOfMeasure']}>
              <FormValue />
            </Form.Item>
          </DetailsItemBlock>
          <DetailsItemBlock title={'Size & Fit Comments'}>
            <FormatText
              value={order.stylingProfile.questionnaireComments}
              placeholder={NO_COMMENTS_PROVIDED}
            />
          </DetailsItemBlock>
          <DetailsItemBlock title={'Customer Comments'}>
            <FormatText value={order.customerComments} placeholder={NO_COMMENTS_PROVIDED} />
          </DetailsItemBlock>
          <DetailsItemBlock title={'Stylist comments'}>
            <Form.Item {...cfg.STYLIST_COMMENTS}>
              <FormatText value={order.stylistComments} placeholder={NO_COMMENTS_PROVIDED} />
            </Form.Item>
          </DetailsItemBlock>
        </OrderLayoutGrid>
        <ButtonWrapper>
          <Button onClick={onGoToEdit} type="primary" shape="round" size="large">
            Edit
          </Button>
        </ButtonWrapper>
      </Form>
    </PageLayout>
  );
};
