import { useInject } from '../../../../providers/DIProvider';
import { OrderStore } from '../../stores';
import { message } from 'antd';
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';

export const useDispatchOrder = (): UseAsyncReturn<void, [number]> => {
  const store = useInject(OrderStore);
  return useAsyncCallback(async (maxDiscount: number) => {
    try {
      await store.setOrderDispatched(maxDiscount);
    } catch (e) {
      message.error('Failed to mark order dispatched. Please try again later.');
    }
  });
};
