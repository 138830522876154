import { useQuery, UseQueryResult } from 'react-query';
import dayjs from 'dayjs';
import { useInject } from '../../../providers/DIProvider';
import { ApiService, OrderListItemResponse, Page } from '../../../services';
import { OrderListItem, OrderStatus } from '../../../dataTypes';

function mapToOrderListItem(item: OrderListItemResponse): OrderListItem {
  const name = item.firstName ? `${item.firstName} ${item.lastName}` : item.name;
  return {
    ...item,
    dateOfBirth: dayjs(item.dateOfBirth),
    status: OrderStatus[item.status as keyof typeof OrderStatus],
    createdDate: dayjs(item.createdDate),
    lastModifiedDate: dayjs(item.lastModifiedDate),
    name: name,
  } as OrderListItem;
}

interface PageOptions {
  pageNumber: number;
  pageSize: number;
}

export function useOrderList(
  pageOptions: PageOptions,
  statuses: string[],
): UseQueryResult<Page<OrderListItem>> {
  const apiService = useInject<ApiService>(ApiService);

  return useQuery(['ordersList', pageOptions, statuses], async () => {
    const { pageNumber, pageSize } = pageOptions;

    const ordersResponse = await apiService.getOrderList(
      (pageNumber - 1) * pageSize,
      pageSize,
      statuses,
    );

    return {
      ...ordersResponse,
      items: ordersResponse.items.map(mapToOrderListItem),
    } as Page<OrderListItem>;
  });
}
