import { Layout } from 'antd';
import React, { PropsWithChildren } from 'react';
import { FC } from 'react';
import styles from './ContentWrapper.module.scss';
import { Content, Header } from 'antd/lib/layout/layout';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import { LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

/* istanbul ignore file*/

export interface User {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
}

export const ContentWrapperComponent: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const oauth: Auth0ContextInterface = useAuth0();
  const user = oauth.user as User | undefined;
  const onLogout = (): void => oauth.logout();
  return (
    <div className="App">
      <Layout className={styles.layout}>
        <Header className={styles.header}>
          <Link to="/" className={styles.logoLink}>
            <div className={styles.logo}>
              <img src="/logo.svg" className={styles.img} alt="" />
              <div className={styles.wrapper}>admin</div>
            </div>
          </Link>
          {user ? (
            <div className={styles.logoutBlock}>
              <div className={styles.hi}>
                {user.name}
                <img className={styles.ava} src={user.picture} alt="" />
              </div>
              <LogoutOutlined onClick={onLogout} />
            </div>
          ) : null}
        </Header>
        <Content>{children}</Content>
      </Layout>
    </div>
  );
};
