import { Table } from 'antd';
import React, { FC } from 'react';
import { OrderItem } from '../../../../../../dataTypes';
import { getCurrencyString } from '../../../../../../utils';
import { PriceSelectorItemDetails } from './PriceSelectorItemDetails/PriceSelectorItemDetails';
import styles from './PriceSelectorItems.module.scss';

const { Column } = Table;

type OrderItemKey = OrderItem['id'];

interface Props {
  items: OrderItem[];
  itemsPurchasedIds: OrderItemKey[];
  onChangeItemsPurchasedIds?(ids: OrderItemKey[]): void;
}

export const PriceSelectorItems: FC<Props> = ({
  items,
  itemsPurchasedIds,
  onChangeItemsPurchasedIds,
}: Props) => {
  return (
    <Table
      dataSource={items}
      rowKey="id"
      className={styles.table}
      pagination={false}
      expandable={{
        // eslint-disable-next-line react/display-name
        expandedRowRender: (record: OrderItem) => <PriceSelectorItemDetails item={record} />,
      }}
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[]) => {
          onChangeItemsPurchasedIds?.(selectedRowKeys as OrderItemKey[]);
        },
        selectedRowKeys: itemsPurchasedIds,
      }}
    >
      <Column title={<span className={styles.title}>Brand</span>} dataIndex="brand" key="brand" />
      <Column
        title={<span className={styles.title}>Category</span>}
        dataIndex="websiteCategory"
        key="websiteCategory"
      />
      <Column
        title={<span className={styles.title}>Colour</span>}
        dataIndex="colour"
        key="colour"
      />
      <Column
        title={<span className={styles.title}>Size</span>}
        width={'10%'}
        dataIndex="size"
        key="size"
      />
      <Column
        title={<span className={styles.title}>Choixce Price</span>}
        width={'10%'}
        dataIndex="choixcePrice"
        key="choixcePrice"
        align="right"
        render={(choixcePrice: number) => <span>{getCurrencyString(choixcePrice)}</span>}
      />
    </Table>
  );
};
