import React, { FC } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons';
import { useRevertToSubmitted } from './useRevertToSubmitted';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const RevertToSubmittedButton: FC = () => {
  const { execute, loading } = useRevertToSubmitted();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to revert this order to submitted status? Deposit(s) taken will be refunded back to customer too."
    >
      <ArrowLeftOutlined />
      Revert to Submitted
    </ConfirmableButton>
  );
};
