import React, { FC } from 'react';
import { UploadOutlined } from '@ant-design/icons/lib/icons';
import { useUnarchiveOrder } from './useUnarchiveOrder';
import { ConfirmableButton } from '../../../../components/ConfirmableButton';

export const UnarchiveOrderButton: FC = () => {
  const { execute, loading } = useUnarchiveOrder();

  return (
    <ConfirmableButton
      disabled={loading}
      onConfirm={execute}
      confirmationText="Are you sure you want to unarchive this order?"
    >
      <UploadOutlined />
      Unarchive
    </ConfirmableButton>
  );
};
