import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { InitLoadingComponent } from 'components/InitLoadingComponent';

const onRedirecting = (): JSX.Element => <InitLoadingComponent />;

const ProtectedArea: React.FC = withAuthenticationRequired(
  ({ children }) => {
    return <>{children}</>;
  },
  {
    onRedirecting,
  },
);

export default ProtectedArea;
