import { message } from 'antd';
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import { useInject } from '../../../../providers/DIProvider';
import { ShippingStore } from '../../stores/ShippingStore';

export function useBookAndPrint(): UseAsyncReturn<void, []> {
  const shippingStore = useInject(ShippingStore);

  return useAsyncCallback(async () => {
    try {
      if (!shippingStore.isShipmentBooked) {
        await shippingStore.postShipment();
      }
      await shippingStore.print();
    } catch (e) {
      message.error(
        'Failed to book a shipment. Please verify delivery address of the order ot try again later.',
      );
    }
  });
}
