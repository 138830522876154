import {
  showPerformChargeErrorMessage,
  showPerformChargeSuccessMessage,
} from 'components/Messages/showPerformChargeMessages';
import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import { useOrderStore } from '../../hooks/useOrderStore';

/* istanbul ignore file */

export function usePerformCharge(): UseAsyncReturn<void, []> {
  const store = useOrderStore();
  return useAsyncCallback(async () => {
    try {
      await store.performCharge();
      showPerformChargeSuccessMessage();
    } catch (e) {
      showPerformChargeErrorMessage();
    }
  });
}
