import { FormItemProps } from 'antd/lib/form';
import { noAngleBracketsRegex, phoneRegex, postcodeRegex } from '../../../../constants';

export enum OrderFormFields {
  STATUS = 'STATUS',
  PROFILE_FIRSTNAME = 'PROFILE_FIRSTNAME',
  PROFILE_LASTNAME = 'PROFILE_LASTNAME',
  PROFILE_PHONE = 'PROFILE_PHONE',
  PROFILE_EMAIL = 'PROFILE_EMAIL',
  PROFILE_COMMUNICATION_METHOD = 'PROFILE_COMMUNICATION_METHOD',
  COMMUNICATION_METHOD = 'COMMUNICATION_METHOD',
  COMMUNICATION_DATE = 'COMMUNICATION_DATE',
  COMMUNICATION_TIME = 'COMMUNICATION_TIME',
  ADDRESS_NAME = 'ADDRESS_NAME',
  ADDRESS_PHONE = 'ADDRESS_PHONE',
  ADDRESS_HOUSE = 'ADDRESS_HOUSE',
  ADDRESS_STREET = 'ADDRESS_STREET',
  ADDRESS_CITY = 'ADDRESS_CITY',
  ADDRESS_COUNTY = 'ADDRESS_COUNTY',
  ADDRESS_POSTCODE = 'ADDRESS_POSTCODE',
  STYLING_FOR_FIRSTNAME = 'STYLING_FOR_FIRSTNAME',
  STYLING_FOR_LASTNAME = 'STYLING_FOR_LASTNAME',
  STYLING_FOR_BIRTHDAY = 'STYLING_FOR_BIRTHDAY',
  STYLING_FOR_GENDER = 'STYLING_FOR_GENDER',
  INTENTION = 'INTENTION',
  CATEGORIES = 'CATEGORIES',
  STYLIST_COMMENTS = 'STYLIST_COMMENTS',
}

interface Options {
  options?: { [key: string]: string };
}

const requiredRule = {
  required: true,
  message: 'Should not be empty',
};

const phoneLengthRule = {
  message: 'Must be between 9 and 20 symbols',
  min: 9,
  max: 20,
};

const phoneRegexRule = {
  message: 'Must be valid phone number',
  pattern: phoneRegex,
};

const postcodeLengthRule = {
  message: 'Must be between 5 and 8 symbols',
  min: 5,
  max: 8,
};

const postcodeRegexRule = {
  message: 'Must be valid UK postcode',
  pattern: postcodeRegex,
};

const noAngleBracketsRegexRule = {
  message: 'Must not include < and > symbols',
  pattern: noAngleBracketsRegex,
};

export const orderFormConfig: { [key in OrderFormFields]: FormItemProps & Options } = {
  [OrderFormFields.STATUS]: {
    label: 'Status',
    name: ['status'],
    rules: [requiredRule],
  },
  [OrderFormFields.PROFILE_FIRSTNAME]: {
    label: 'First name',
    name: ['customerProfile', 'firstName'],
    rules: [
      requiredRule,
      {
        max: 50,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.PROFILE_LASTNAME]: {
    label: 'Last name',
    name: ['customerProfile', 'lastName'],
    rules: [
      requiredRule,
      {
        max: 50,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.PROFILE_PHONE]: {
    label: 'Phone',
    name: ['customerProfile', 'phone'],
    rules: [requiredRule, phoneLengthRule, phoneRegexRule],
  },
  [OrderFormFields.PROFILE_EMAIL]: {
    label: 'Email',
    name: ['customerProfile', 'email'],
    rules: [
      requiredRule,
      {
        message: 'Cannot be longer than 50 symbols',
        max: 50,
      },
    ],
  },
  [OrderFormFields.PROFILE_COMMUNICATION_METHOD]: {
    label: 'Communicate by',
    name: ['customerProfile', 'communicationMethod'],
    rules: [],
  },
  [OrderFormFields.COMMUNICATION_METHOD]: {
    label: 'Communicate by',
    name: ['communicationMethod'],
    rules: [],
  },
  [OrderFormFields.COMMUNICATION_DATE]: {
    label: 'On',
    name: ['communicationDateTime', 'date'],
    rules: [],
  },
  [OrderFormFields.COMMUNICATION_TIME]: {
    label: 'At',
    name: ['communicationDateTime', 'time'],
    rules: [],
  },
  [OrderFormFields.ADDRESS_NAME]: {
    label: 'Full name',
    name: ['address', 'name'],
    rules: [
      requiredRule,
      {
        message: 'Cannot be longer than 35 symbols',
        min: 1,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.ADDRESS_PHONE]: {
    label: 'Phone',
    name: ['address', 'phone'],
    rules: [requiredRule, phoneLengthRule, phoneRegexRule],
  },
  [OrderFormFields.ADDRESS_HOUSE]: {
    label: 'House name / No',
    name: ['address', 'house'],
    rules: [
      requiredRule,
      {
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.ADDRESS_STREET]: {
    label: 'Street name',
    name: ['address', 'street'],
    rules: [
      requiredRule,
      {
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.ADDRESS_CITY]: {
    label: 'City',
    name: ['address', 'city'],
    rules: [
      requiredRule,
      {
        min: 2,
        max: 35,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.ADDRESS_COUNTY]: {
    label: 'County',
    name: ['address', 'county'],
    rules: [
      {
        max: 32,
      },
      noAngleBracketsRegexRule,
    ],
  },
  [OrderFormFields.ADDRESS_POSTCODE]: {
    label: 'Postcode',
    name: ['address', 'postcode'],
    rules: [requiredRule, postcodeRegexRule, postcodeLengthRule],
  },
  [OrderFormFields.STYLING_FOR_FIRSTNAME]: {
    label: 'First Name',
    name: ['stylingProfile', 'firstName'],
    rules: [
      {
        required: true,
        message: 'First name should not be empty',
      },
      {
        max: 50,
      },
    ],
  },
  [OrderFormFields.STYLING_FOR_LASTNAME]: {
    label: 'Last Name',
    name: ['stylingProfile', 'lastName'],
    rules: [
      {
        required: true,
        message: 'Last name should not be empty',
      },
      {
        max: 50,
      },
    ],
  },
  [OrderFormFields.STYLING_FOR_BIRTHDAY]: {
    label: 'Birthday',
    name: ['stylingProfile', 'dateOfBirth'],
    rules: [requiredRule],
  },
  [OrderFormFields.STYLING_FOR_GENDER]: {
    label: 'Gender',
    name: ['stylingProfile', 'gender'],
    rules: [requiredRule],
  },
  [OrderFormFields.INTENTION]: {
    label: 'Looking for',
    name: ['intention'],
    rules: [],
  },
  [OrderFormFields.CATEGORIES]: {
    wrapperCol: { span: 24 },
    name: ['clothingCategories'],
    rules: [],
  },
  [OrderFormFields.STYLIST_COMMENTS]: {
    wrapperCol: { span: 24 },
    name: ['stylistComments'],
  },
};
