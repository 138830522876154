import { useAsyncCallback, UseAsyncReturn } from 'react-async-hook';
import {
  showChargeRequestSuccessMessage,
  showChargeRequestErrorMessage,
} from '../../../../components/Messages';
import { useOrderStore } from '../../hooks/useOrderStore';

/* istanbul ignore file */

export function useRequestCharge(): UseAsyncReturn<void, [number]> {
  const store = useOrderStore();

  return useAsyncCallback(async (chargeAmount: number) => {
    try {
      await store.requestCharge(chargeAmount);
      showChargeRequestSuccessMessage();
    } catch (e) {
      showChargeRequestErrorMessage();
    }
  });
}
