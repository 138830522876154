import { notification } from 'antd';
import { PURCHASE_SUCCESS_DESCRIPTION, PURCHASE_SUCCESS_MESSAGE } from '../../consts';

export const showPurchaseSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: PURCHASE_SUCCESS_MESSAGE,
    description: PURCHASE_SUCCESS_DESCRIPTION,
  });
};
