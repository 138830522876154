/* istanbul ignore file */

const ROOT_URL = '/';
export const makeGetOrderListUrl = (): string => `${ROOT_URL}orders`;

export const makeGetOrderUrl = (orderId: string): string => `${makeGetOrderListUrl()}/${orderId}`;
export const makePutOrderUrl = makeGetOrderUrl;

export const makePostOrderItemsUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/items`;

export const makePutOrderDispatchUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/dispatch`;

export const makeArchiveOrderUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/archive`;

export const makeUnarchiveOrderUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/unarchive`;

export const makeSetOrderReadyForPickingUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/ready-for-picking`;

export const makeRevertOrderToSubmittedUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/revert-to-submitted`;

export const makePutOrderCancelUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/cancel`;

export const makePutOrderDeliveredUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/deliver`;

export const makePutOrderReturnUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/return`;
export const makeGetOrderReturnUrl = makePutOrderReturnUrl;

export const makePutCompleteUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/complete`;

export const makePostOrderPurchaseUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/purchase`;

export const makePostOrderShipmentUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/shipment`;
export const makeGetOrderShipmentUrl = makePostOrderShipmentUrl;

export const makeGetOrderShipmentLabelUrl = (orderId: string): string =>
  `${makeGetOrderShipmentUrl(orderId)}/label`;

export const makeGetOrderPaymentMethodsUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/payment-methods`;

export const makePatchPaymentMethodUrl = (paymentMethodId: string): string =>
  `${ROOT_URL}payment-methods/${paymentMethodId}`;

export const makeRevertOrderToDeliveredUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/revert-to-delivered`;

export const makeGetUserPermissionsUrl = (): string => `${ROOT_URL}permissions`;

export const makeRequestChargeUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/charge-request`;

export const makeGetChargeRequestUrl = makeRequestChargeUrl;

export const makePerformChargeUrl = (orderId: string): string =>
  `${makeRequestChargeUrl(orderId)}/perform`;

export const makePostTakePaymentUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/payment`;

export const makeTakeDepositUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/take-deposit`;

export const makeCaptureDepositUrl = (orderId: string): string =>
  `${makeGetOrderUrl(orderId)}/capture-deposit`;
