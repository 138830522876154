import notification from 'antd/lib/notification';
import {
  UPDATE_CARD_SUCCESS_MESSAGE,
  UPDATE_CARD_ERROR_MESSAGE,
  UPDATE_CARD_DESCRIPTION,
  UPDATE_CARD_SUCCESS_DESCRIPTION,
} from '../../consts';

export const showUpdatePaymentMethodErrorMessage = (): void => {
  /* istanbul ignore next */
  notification.error({
    message: UPDATE_CARD_ERROR_MESSAGE,
    description: UPDATE_CARD_DESCRIPTION,
  });
};

export const showUpdatePaymentMethodSuccessMessage = (): void => {
  /* istanbul ignore next */
  notification.success({
    message: UPDATE_CARD_SUCCESS_MESSAGE,
    description: UPDATE_CARD_SUCCESS_DESCRIPTION,
  });
};
