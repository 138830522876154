import { Dayjs } from 'dayjs';

export interface UrlParams {
  id: string;
  state: string;
}

export declare type StylingFor = 'men' | 'kids';

export declare type Gender = 'Male' | 'Boy' | 'Girl';

export interface OrderListItem {
  id: string;
  name: string;
  gender: Gender;
  dateOfBirth: Dayjs;
  intention: string;
  clothingCategories: string[];
  status: string;
  createdDate: Dayjs;
  lastModifiedDate: Dayjs;
}

export enum OrderStatus {
  Submitted = 'Submitted',
  ReadyForPicking = 'Ready For Picking',
  Dispatched = 'Dispatched',
  Delivered = 'Delivered',
  DeliveryOverdue = 'Delivery Overdue',
  PendingPayment = 'Pending Payment',
  PaymentOverdue = 'Payment Overdue',
  PendingReturn = 'Pending Return',
  ReturnOverdue = 'Return Overdue',
  Archived = 'Archived',
  Cancelled = 'Cancelled',
  FullyReturned = 'Fully Returned',
  Completed = 'Completed',
}

export enum ChargeRequestStatus {
  Made = 'Made',
  PaymentFailed = 'PaymentFailed',
  Completed = 'Completed',
}

export interface ChargeRequestTotals {
  noOfItems: number;
  costPrice: number;
  fullPrice: number;
  chargeAmount: number;
  discountAmount: number;
  discountPercentage: number;
}

export interface ChargeRequest {
  id: string;
  status: string;
  orderId: string;
  items: OrderItem[];
  totals: ChargeRequestTotals;
}

export interface StylingProfile {
  firstName: string;
  lastName: string;
  dateOfBirth: Dayjs;
  gender: Gender;
  questionnaire: QuestionnaireItem[];
  questionnaireComments?: string;
  systemOfMeasure?: SystemOfMeasure;
}

export interface QuestionnaireItem {
  question: string;
  answers: string[];
}

export interface CommunicationDateTime {
  date?: string;
  time?: string;
}

export interface CustomerProfile {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  communicationMethod?: string;
}

export interface Address {
  name: string;
  phone: string;
  house: string;
  street: string;
  city: string;
  county?: string;
  postcode: string;
}

export interface OrderItem {
  id: string;
  sku: string;
  ean: string;
  hsCode: string;
  division: string;
  websiteCategory: string;
  category: string;
  brand: string;
  itemName: string;
  colour: string;
  fabricComposition: string;
  countryOfOrigin: string;
  size: string;
  weight: number;
  choixcePrice: number;
  costPrice: number;
}

export interface Order {
  id: string;
  status: OrderStatus;
  stylingFor: StylingFor;
  intention: string;
  clothingCategories: string[];
  stylingProfile: StylingProfile;
  customerProfile: CustomerProfile;
  communicationDateTime: CommunicationDateTime;
  address: Address;
  stylistComments: string;
  customerComments: string;
  salesforceOpportunityLink?: string;
  items?: OrderItem[];
  deposits?: Credit[];
  lastModifiedDate: Dayjs;
}

export interface Purchase {
  purchasedItemIds: string[];
  sellPrice: number;
}

interface HttpResponse {
  status?: number;
}

export interface HttpError {
  response?: HttpResponse;
}

export enum LoadingStatuses {
  Init = 'Init',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Error = 'Error',
}
export enum SystemOfMeasure {
  Imperial = 'Imperial',
  Metric = 'Metric',
}

export interface PurchasedOrder extends Order {
  purchase: PurchaseInfo;
}

export interface PurchaseInfo {
  purchasedItems: OrderItem[];
  returnedItems: OrderItem[];
  totals: Totals;
  calculationSettings: CalculationSettings;
}
export interface UploadingError {
  errorMessage: string[];
}

export interface CalculationSettings {
  costPriceSurcharge: number;
  multiplierToEarnCommission: number;
  stylistInterest: number;
}

export interface PurchasedItem {
  id: string;
  sku: string;
  ean: string;
  hsCode: string;
  division: string;
  category: string;
  brand: string;
  itemName: string;
  colour: string;
  fabricComposition: string;
  countryOfOrigin: string;
  size: string;
  weight: number;
  choixcePrice: number;
  costPrice: number;
}

export interface Totals {
  numberOfPurchasedItems: number;
  costPrice: number;
  sellPrice: number;
  stylistCommission: number;
  profit: number;
  margin: number;
}

export interface Credit {
  type: 'Deposit' | 'Voucher';
  status: 'Active' | 'Spent';
  amount: number;
  amountSpent: number;
  amountRefunded: number;
}

export class SelectQuestion {
  constructor(public readonly label: string, public readonly options: string[]) {}
}

export enum PaymentResultStatus {
  Processing = 'Processing',
  Completed = 'Completed',
  Failed = 'Failed',
}

export type PaymentResult =
  | { status: PaymentResultStatus.Processing }
  | { status: PaymentResultStatus.Completed }
  | { status: PaymentResultStatus.Failed; errorMessage: string };

export interface PaymentMethod {
  id: string;
  isDefault: boolean;
  card: Card;
  billingAddress?: BillingAddress;
}
export interface BillingAddress {
  firstName: string;
  lastName: string;
  phone: string;
  house: string;
  street: string;
  city: string;
  county?: string;
  postcode: string;
}

export interface Card {
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cardholderName: string;
}
