import React, { FC } from 'react';
import { Alert } from 'antd';
import { OrderStatus } from '../../../dataTypes';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { useOrderStore } from '../hooks/useOrderStore';
import { SubmittedStatusView } from './SubmittedStatusView';
import { DispatchedStatusView } from './DispatchedStatusView';
import { DeliveredStatusView } from './DeliveredStatusView';
import { DeliveryOverdueStatusView } from './DeliveryOverdueStatusView';
import { PendingPaymentStatusView } from './PendingPaymentStatusView';
import { PendingReturnStatusView } from './PendingReturnStatusView';
import { CancelledStatusView } from './CancelledStatusView';
import { FullyReturnedStatusView } from './FullyReturnedStatusView';
import { CompletedStatusView } from './CompletedStatusView';
import { PaymentOverdueStatusView } from './PaymentOverdueStatusView';
import { ArchivedStatusView } from './ArchivedStatusView';
import { observer } from 'mobx-react-lite';

import styles from './StatusViewManager.module.scss';
import { ReadyForPickingStatusView } from './ReadyForPickingStatusView';

export const StatusViewManager: FC = observer(() => {
  const { currentOrder } = useOrderStore();

  return (
    <div>
      <ErrorBoundary
        message={
          <Alert
            className={styles.error}
            message="Oops! Something went wrong, please contact your system administrator."
            type="error"
          />
        }
      >
        {currentOrder.status === OrderStatus.Submitted && <SubmittedStatusView />}
        {currentOrder.status === OrderStatus.ReadyForPicking && <ReadyForPickingStatusView />}
        {currentOrder.status === OrderStatus.Dispatched && <DispatchedStatusView />}
        {currentOrder.status === OrderStatus.Delivered && <DeliveredStatusView />}
        {currentOrder.status === OrderStatus.PaymentOverdue && <PaymentOverdueStatusView />}
        {currentOrder.status === OrderStatus.DeliveryOverdue && <DeliveryOverdueStatusView />}
        {currentOrder.status === OrderStatus.PendingPayment && <PendingPaymentStatusView />}
        {currentOrder.status === OrderStatus.PendingReturn && <PendingReturnStatusView />}
        {currentOrder.status === OrderStatus.ReturnOverdue && <PendingReturnStatusView />}
        {currentOrder.status === OrderStatus.Archived && <ArchivedStatusView />}
        {currentOrder.status === OrderStatus.Cancelled && <CancelledStatusView />}
        {currentOrder.status === OrderStatus.FullyReturned && <FullyReturnedStatusView />}
        {currentOrder.status === OrderStatus.Completed && <CompletedStatusView />}
      </ErrorBoundary>
    </div>
  );
});
