import { OrderItem } from 'dataTypes';
import { sum } from 'utils';

export interface Totals {
  chargeAmount: number;
  costPrice: number;
  fullPrice: number;
  discountAmount: number;
  discountPercentage: number;
}

export const calculateFullPrice = (items: OrderItem[]): number => {
  if (!items.length) {
    throw new Error('Cannot calculate totals when no items are provided.');
  }

  return items.map((el) => el.choixcePrice).reduce(sum, 0);
};

export const calculateTotals = (items: OrderItem[], chargeAmount: number): Totals => {
  if (!items.length) {
    throw new Error('Cannot calculate totals when no items are provided.');
  }
  if (chargeAmount <= 0) {
    throw new Error('Cannot calculate totals when charge amount is 0 or negative.');
  }

  const fullPrice = calculateFullPrice(items);
  const discountAmount = fullPrice - chargeAmount;

  return {
    chargeAmount,
    costPrice: items.map((el) => el.costPrice).reduce(sum, 0),
    fullPrice,
    discountAmount,
    discountPercentage: Math.round((discountAmount / fullPrice) * 100),
  } as Totals;
};
